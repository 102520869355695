import {ReduxActionType} from '../../commons/interfaces'
import * as types from './types'

const INITIAL_STATE: types.SettingsState = {
  orgName: null,
  orgLogo: null,
  isNameLoading: false,
  isNameEditable: true,
  isLogoLoading: false,
  isLogoEditable: false,
  nameErrorKey: null,
  logoErrorKey: null,
  uploadProgress: null,
  isAlarm112Disabled: false,
  isAlarm112Loading: false
}

const reducer = (state = INITIAL_STATE, action: ReduxActionType) => {
  switch (action.type) {
    case types.ORG_NAME_SAVE:
      return {...state, isNameLoading: true}
    case types.ORG_NAME_SAVE_SUCCESS:
      return {
        ...state,
        isNameLoading: false,
        isNameEditable: false,
        orgName: action.payload,
        nameErrorKey: ''
      }
    case types.ORG_NAME_SAVE_FAIL:
      return {
        ...state,
        isNameLoading: true,
        orgName: action.payload,
        isNameEditable: true,
        nameErrorKey: action.msg
      }
    case types.ORG_NAME_EDIT:
      return {...state, isNameEditable: action.payload}
    case types.ORG_NAME_REMOVE:
      return {...state, isNameLoading: true}
    case types.ORG_NAME_REMOVE_SUCCESS:
      return {...state, isNameLoading: false, orgName: '', isNameEditable: true, nameErrorKey: null}
    case types.ORG_NAME_REMOVE_FAIL:
      return {...state, isNameLoading: false, nameErrorKey: action.msg}
    case types.ORG_NAME_REQUEST:
      return {...state, isNameLoading: true, isLogoLoading: true}
    case types.ORG_NAME_REQUEST_SUCCESS:
      return {
        ...state,
        isNameLoading: false,
        isNameEditable: false,
        isLogoLoading: false,
        isLogoEditable: false,
        orgName: action.payload.orgName,
        orgLogo:
          action.payload.orgLogo &&
          `data:${action.payload.orgLogo.type};base64,${action.payload.orgLogo.value}`,
        isAlarm112Disabled: action.payload.alarm112Disabled
      }
    case types.ORG_NAME_REQUEST_FAIL:
      return {...state, isNameLoading: false, nameErrorKey: action.msg}
    case types.ORG_LOGO_SAVE:
      return {...state, isLogoLoading: true, logoErrorKey: null}
    case types.ORG_LOGO_SAVE_SUCCESS:
      return {
        ...state,
        orgLogo: action.payload,
        isLogoLoading: false,
        isLogoEditable: false,
        uploadProgress: null,
        logoErrorKey: null
      }
    case types.ORG_LOGO_SAVE_FAIL:
      return {...state, logoErrorKey: action.msg, isLogoLoading: false}
    case types.ORG_LOGO_EDIT:
      return {...state, isLogoEditable: action.payload}
    case types.ORG_LOGO_REMOVE:
      return {...state, isLogoLoading: true}
    case types.ORG_LOGO_REMOVE_SUCCESS:
      return {...state, isLogoLoading: false, orgLogo: null, isLogoEditable: true}
    case types.ORG_LOGO_REMOVE_FAIL:
      return {...state, isLogoLoading: false, errorMsg: action.msg}
    case types.ORG_LOGO_UPLOADING:
      return {...state, uploadProgress: action.payload}
    case types.ORG_NAME_SHOW_ERROR:
      return {...state, nameErrorKey: action.msg}
    case types.ORG_LOGO_SHOW_ERROR:
      return {...state, logoErrorKey: action.msg}
    case types.ORG_ENABLE_112_UPDATE_REQUESTED:
      return {
        ...state,
        isAlarm112Disabled: action.payload,
        isAlarm112Loading: true
      }
    case types.ORG_ENABLE_112_UPDATE_SUCCESS:
      return {
        ...state,
        isAlarm112Disabled: 'true' === action.payload.toLowerCase(),
        isAlarm112Loading: false
      }
    case types.ORG_ENABLE_112_UPDATE_FAIL:
      const revertedAlarm112DisabledState = !state.isAlarm112Disabled
      return {...state, isAlarm112Disabled: revertedAlarm112DisabledState, isAlarm112Loading: false}
    default:
      return state
  }
}

export default reducer
