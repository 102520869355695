const ADDRESS_FETCH_REQUESTED = 'safe/address/address_fetch_requested'
const ADDRESS_FETCH_SUCCEEDED = 'safe/address/address_fetch_succeeded'
const ADDRESS_FETCH_FAILED = 'safe/address/address_fetch_failed'
const ADDRESS_INSERT_REQUESTED = 'safe/address/address_insert_requested'
const ADDRESS_INSERT_SUCCEEDED = 'safe/address/address_insert_succeeded'
const ADDRESS_INSERT_FAILED = 'safe/address/address_insert_failed'
const ADDRESS_UPDATE_REQUESTED = 'safe/address/address_update_requested'
const ADDRESS_UPDATE_SUCCEEDED = 'safe/address/address_update_succeeded'
const ADDRESS_UPDATE_FAILED = 'safe/address/address_update_failed'
const ADDRESS_DELETE_REQUESTED = 'safe/address/address_delete_requested'
const ADDRESS_DELETE_SUCCEEDED = 'safe/address/address_delete_succeeded'
const ADDRESS_DELETE_FAILED = 'safe/address/address_delete_failed'
const ADDRESS_DETAIL_FETCH_REQUESTED = 'safe/address/address_detail_fetch_requested'
const ADDRESS_DETAIL_FETCH_SUCCEEDED = 'safe/address/address_detail_fetch_succeeded'
const ADDRESS_DETAIL_FETCH_FAILED = 'safe/address/address_detail_fetch_failed'
const ADDRESS_UPLOAD_REQUESTED = 'safe/address/upload_requested'
const ADDRESS_UPLOAD_SUCCEEDED = 'safe/address/upload_succeeded'
const ADDRESS_UPLOAD_FAILED = 'safe/address/upload_failed'
const ADDRESS_UPLOAD_EMPTY = 'safe/address/upload_empty'
const ADDRESS_DETAIL_EDIT = 'safe/address/edit_detail'
const CLEAR_ADDRESS_DETAIL = 'safe/address/clear_detail'
const CLEAR_MESSAGE = 'safe/address/clear_message'

export {
  ADDRESS_FETCH_REQUESTED,
  ADDRESS_FETCH_SUCCEEDED,
  ADDRESS_FETCH_FAILED,
  ADDRESS_INSERT_REQUESTED,
  ADDRESS_INSERT_SUCCEEDED,
  ADDRESS_INSERT_FAILED,
  ADDRESS_UPDATE_REQUESTED,
  ADDRESS_UPDATE_SUCCEEDED,
  ADDRESS_UPDATE_FAILED,
  ADDRESS_DELETE_REQUESTED,
  ADDRESS_DELETE_SUCCEEDED,
  ADDRESS_DELETE_FAILED,
  ADDRESS_DETAIL_FETCH_REQUESTED,
  ADDRESS_DETAIL_FETCH_SUCCEEDED,
  ADDRESS_DETAIL_FETCH_FAILED,
  ADDRESS_DETAIL_EDIT,
  ADDRESS_UPLOAD_REQUESTED,
  ADDRESS_UPLOAD_SUCCEEDED,
  ADDRESS_UPLOAD_FAILED,
  ADDRESS_UPLOAD_EMPTY,
  CLEAR_ADDRESS_DETAIL,
  CLEAR_MESSAGE
}
