// @ts-check
import React, {Suspense} from 'react'
import './App.css'
// import ReportScreen from './screens/ReportScreen'
import LoginScreen from './features/auth/LoginScreen'
import LoadingIndicator from './components/LoadingIndicator'
import './assets/scss/main.scss'

function App() {
  return (
    <Suspense fallback={<LoadingIndicator open={true} />}>
      <LoginScreen />
    </Suspense>
  )
}

export default App
