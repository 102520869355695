import React, {useEffect, useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {useHistory} from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {Form, Field} from 'react-final-form'
import {useTranslation} from 'react-i18next'

import * as actions from './actions'
import Sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import LoadingIndicator from '../../components/LoadingIndicator'
import TextFieldAdapter from '../../components/forms/TextFieldAdapter'
import {required, postalValidation} from '../../commons/validator'

const useStyles = makeStyles({
  heading: {
    backgroundColor: Colors.primary,
    color: 'white',
    fontWeight: 'bolder',
    padding: '1rem',
    marginBottom: Sizes.defaultPadding
  },
  btn: {
    marginRight: '0.5rem'
  },
  btnContainer: {
    marginBottom: Sizes.defaultMargin
  },
  chkBox: {
    color: Colors.primary
  },
  inputField: {
    color: Colors.textLight
  },
  uploadIcon: {
    position: 'absolute',
    zIndex: 3,
    padding: '.25rem',
    color: Colors.primaryDarkest,
    fontSize: '1.3rem',
    right: 0
  },
  uploadInput: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
    margin: 0,
    opacity: 0,
    height: 'calc(2.5rem + 2px)'
  },
  uploadLbl: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: 'white',
    color: Colors.text,
    padding: '.375rem .75rem'
  },
  uploadContainer: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    height: 'calc(2.5rem + 2px)'
  },
  root: {
    objectFit: 'contain'
  },
  section: {
    marginBottom: Sizes.largeMargin
  }
})

const AddressDetailScreen = props => {
  const classes = useStyles()
  const history = useHistory()
  const {t, i18n} = useTranslation()

  const defaultFile = {name: t('common.chooseFile'), size: 0}
  const [file, setFile] = useState(defaultFile)

  useEffect(() => {
    props.dispatch(actions.clearMsg())
    const {addressId} = props.match.params
    if (addressId) {
      props.dispatch(actions.addressDetailFetchRequested(addressId))
    }

    return () => props.dispatch(actions.addressClearDetail())
  }, [])

  const _onSaveAddress = values => {
    const detailAddress = {
      ...props.addressReducer.selectedAddress,
      address: values.address,
      city: values.city,
      postalCode: values.postalCode,
      additionalInfo: values.additionalInfo
    }

    if (props.addressReducer.selectedAddress.id) {
      props.dispatch(actions.addressUpdateRequested(detailAddress, history))
    } else {
      props.dispatch(actions.addressInsertRequested(detailAddress, history))
    }
  }

  const _onBackPressed = () => {
    history.goBack()
  }

  const _onUploadChange = event => {
    const uploadedFile = event.target.files[0]
    if (uploadedFile) {
      setFile(uploadedFile)
    }
  }

  const _onUploadPressed = () => {
    if (file.size > 0) {
      props.dispatch(actions.addressUploadRequested(file, history))
    } else {
      props.dispatch(actions.addressUploadEmpty())
    }
  }

  return (
    <Container className={classes.root}>
      <div className={classes.pageTitle}>
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            className={classes.btn}
            href={process.env.PUBLIC_URL + '/templates/Locatie.xlsx'}>
            {t('location.downloadTemplate')}
          </Button>
        </div>
      </div>

      <Form
        onSubmit={_onSaveAddress}
        reducer={props.addressReducer}
        initialValues = {{
          address: props.addressReducer.selectedAddress.address,
          city: props.addressReducer.selectedAddress.city,
          additionalInfo: props.addressReducer.selectedAddress.additionalInfo,
          postalCode: props.addressReducer.selectedAddress.postalCode
        }}
        render={props => (
          <Grid item md={9} className={classes.section}>
            <Typography className={classes.heading}>{t('location.addLocation')}</Typography>
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Field
                    name="address"
                    id="address"
                    label={t('location.address')}
                    validate={value => required(value, i18n)}
                    component={TextFieldAdapter}
                    className={classes.inputField}
                    InputProps={{style: {backgroundColor: 'white'}}}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4}>
                  <Field
                    id="city"
                    name="city"
                    label={t('location.city')}
                    validate={value => required(value, i18n)}
                    className={classes.inputField}
                    component={TextFieldAdapter}
                    InputProps={{style: {backgroundColor: 'white'}}}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Field
                    id="postalCode"
                    name="postalCode"
                    label={t('location.postalCode')}
                    validate={value => postalValidation(value, i18n)}
                    className={classes.inputField}
                    component={TextFieldAdapter}
                    InputProps={{style: {backgroundColor: 'white'}}}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4}>
                  <Field
                    id="additionalInfo"
                    name="additionalInfo"
                    label={t('location.additionalInfo')}
                    // validate={value => required(value, i18n)}
                    className={classes.inputField}
                    component={TextFieldAdapter}
                    InputProps={{style: {backgroundColor: 'white'}}}
                    maxLength={80}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Button
                    disabled={props.submitting}
                    variant="contained"
                    color="primary"
                    size="medium"
                    type="submit"
                    className={classes.btn}>
                    {t('common.save')}
                  </Button>
                  <Button
                    disabled={props.submitting}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    className={classes.btn}
                    onClick={_onBackPressed}>
                    {t('common.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        )}
      />
      <Grid item md={9} className={classes.section}>
        {props.addressReducer.alertMsg && (
          <Alert
            severity={props.addressReducer.alertSeverity}
            onClose={() => props.dispatch(actions.clearMsg())}>
            {t(props.addressReducer.alertMsg)}
          </Alert>
        )}
      </Grid>
      <Grid item md={9} className={classes.section}>
        <Typography className={classes.heading}>{t('location.importLocation')}</Typography>
        <Grid item md={4} className={classes.uploadContainer}>
          <input
            accept=".xlsx,.xls"
            className={classes.uploadInput}
            id="contained-button-file"
            type="file"
            onChange={_onUploadChange}
          />
          <label htmlFor="contained-button-file" className={classes.uploadLbl}>
            {file.name}
          </label>
          <AddCircleIcon className={classes.uploadIcon} />
        </Grid>
        <Grid item md={12}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={classes.btn}
            onClick={_onUploadPressed}>
            {t('employee.upload')}
          </Button>
        </Grid>
      </Grid>
      <LoadingIndicator open={props.addressReducer.isLoading} />
    </Container>
  )
}

AddressDetailScreen.propTypes = {
  addressReducer: PropTypes.object
}

const mapStateToProps = state => {
  const {addressReducer} = state
  return {addressReducer}
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => dispatch(action)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressDetailScreen)
