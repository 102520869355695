import * as types from './types'

const INITIAL_STATE = {
  listAddress: [],
  selectedAddress: {},
  isLoading: false,
  totalAddress: 0,
  alertMsg: '',
  alertSeverity: ''
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADDRESS_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case types.ADDRESS_FETCH_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        listAddress: action.payload.addresses.content,
        totalAddress: action.payload.addresses.totalElements
      }
    case types.ADDRESS_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        alertMsg: action.payload.msg,
        alertSeverity: 'error'
      }

    case types.ADDRESS_DETAIL_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case types.ADDRESS_DETAIL_FETCH_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        selectedAddress: action.payload.address
      }
    case types.ADDRESS_DETAIL_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        alertMsg: `server_response:${action.payload.msg}`,
        alertSeverity: 'error'
      }
    case types.ADDRESS_INSERT_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case types.ADDRESS_INSERT_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      }
    case types.ADDRESS_INSERT_FAILED:
      return {
        ...state,
        isLoading: false,
        alertMsg: `server_response:${action.payload.msg}`,
        alertSeverity: 'error'
      }
    case types.ADDRESS_UPDATE_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      }
    case types.ADDRESS_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        alertMsg: `server_response:${action.payload.msg}`,
        alertSeverity: 'error'
      }
    case types.ADDRESS_DELETE_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case types.ADDRESS_DELETE_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      }
    case types.ADDRESS_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        alertMsg: `server_response:${action.payload.msg}`,
        alertSeverity: 'error'
      }
    case types.ADDRESS_UPLOAD_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case types.ADDRESS_UPLOAD_FAILED:
      return {
        ...state,
        isLoading: false,
        alertMsg: action.payload.msg,
        alertSeverity: 'error'
      }
    case types.ADDRESS_UPLOAD_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        alertMsg: action.payload.msg,
        alertSeverity: 'success'
      }
    case types.CLEAR_MESSAGE:
      return {
        ...state,
        alertMsg: '',
        alertSeverity: ''
      }
    case types.ADDRESS_UPLOAD_EMPTY:
      return {
        ...state,
        alertSeverity: 'error',
        alertMsg: 'common.error.fileEmpty'
      }
    case types.CLEAR_ADDRESS_DETAIL:
      return {
        ...state,
        selectedAddress: {}
      }
    default:
      return state
  }
}

export default reducer
