import React, {useEffect, useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import {useTranslation} from 'react-i18next'

import AddressTable from './AddressTable'
import * as actions from './actions'
import AddressFilter from './AddressFilter'
import LoadingIndicator from '../../components/LoadingIndicator'
import Constants from '../../commons/constants/constants'
import Colors from '../../commons/constants/colors'
import sizes from '../../commons/constants/sizes'

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F0F4F7'
  },
  btn: {
    marginLeft: '0.5rem'
  },
  btnContainer: {
    alignSelf: 'center'
  },
  btnText: {
    textTransform: 'lowercase',
    color: '#C7C7C7'
  },
  filterContainer: {
    backgroundColor: '#FFFFFF',
    padding: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  grid: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  pageTitle: {
    display: 'flex'
  },
  select: {
    margin: '0.5rem',
    minWidth: '300px',
    color: '#C7C7C7'
  },
  switch: {
    marginTop: sizes.defaultMargin
  },
  tableContainer: {
    padding: '1rem'
  },
  uploadContainer: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    height: 'calc(2.5rem + 2px)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.text
  },
  uploadIcon: {
    position: 'absolute',
    zIndex: 3,
    padding: '.6rem',
    color: Colors.primaryDarkest,
    fontSize: '1.3rem',
    right: 0
  },
  uploadInput: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
    margin: 0,
    opacity: 0,
    height: 'calc(2.5rem + 2px)'
  },
  uploadLbl: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: 'white',
    color: Colors.text,
    padding: '.6rem .5rem'
  }
})

const DeleteWarningDialog = props => {
  const {t} = useTranslation()
  return (
    <Dialog open={props.isDialogShown}>
      <DialogTitle id="delete-dialog">{'SAFE'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('location.confirmDelete')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={props.onDeleteAddress}>
          {t('common.yes')}
        </Button>
        <Button color="primary" onClick={props.onCancelDelete}>
          {t('common.no')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ImportAddressDialog = props => {
  const {t} = useTranslation()
  const defaultFile = {name: t('common.chooseFile'), size: 0}
  const [file, setFile] = useState(defaultFile)
  const classes = useStyles()
  const _onUploadChange = event => {
    const uploadedFile = event.target.files[0]
    if (uploadedFile) {
      setFile(uploadedFile)
    }
  }

  return (
    <Dialog
      open={props.isDialogShown}
      onClose={() => {
        setFile(defaultFile)
        props.onClose()
      }}>
      <DialogTitle id="delete-dialog">{'SAFE'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('employee.upload')}</DialogContentText>
        <div className={classes.uploadContainer}>
          <input
            accept=".xlsx,.xls"
            className={classes.uploadInput}
            id="contained-button-file"
            type="file"
            onChange={_onUploadChange}
          />
          <label htmlFor="contained-button-file" className={classes.uploadLbl}>
            {file.name}
          </label>
          <AddCircleIcon className={classes.uploadIcon} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={() => props.onImportClicked(file)}>
          {t('employee.upload')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AddressScreen = props => {
  const classes = useStyles()
  const history = useHistory()
  const {t} = useTranslation()

  const [selectedAddressId, setSelectedAddressId] = useState(0)
  const [selectedAddress, setSelectedAddress] = useState()
  const [isDeleteDialogShown, showDeleteDialog] = useState(false)
  const [isImportDialogShown, showImportDialog] = useState(false)
  const [filter, setFilter] = useState(Constants.defaultAddressFilter)
  const [pagination, setPagination] = useState(Constants.defaultAddressPagination)
  const [includeInactive, setIncludeInactive] = useState(false)

  const _onEditAddress = addressId => history.push('/locatie/detail/' + addressId)
  const _onDeletePressed = address => {
    setSelectedAddress(address)
    showDeleteDialog(true)
  }

  const _doDeleteAddress = () => {
    props.dispatch(
      actions.addressDeleteRequested(selectedAddress, filter, pagination, includeInactive)
    )
    setSelectedAddress()
    showDeleteDialog(false)
  }

  const _onRestoreAddress = address => {
    address.active = true
    props.dispatch(actions.addressUpdateRequested(address))
  }

  const _onSearch = updatedFilter => {
    setFilter(updatedFilter)
    props.dispatch(actions.addressFetchRequested(updatedFilter, pagination, includeInactive))
  }

  const _onChangePagination = changedPagination => {
    setPagination(changedPagination)
    props.dispatch(actions.addressFetchRequested(filter, changedPagination, includeInactive))
  }

  const _onImportClicked = () => {
    showImportDialog(true)
  }

  const _onSwitchToggled = () => {
    const currentInactiveState = includeInactive
    setIncludeInactive(!currentInactiveState)
    props.dispatch(actions.addressFetchRequested(filter, pagination, !currentInactiveState))
  }

  useEffect(() => {
    document.title = `SAFE | ${t('location.title')}`

    props.dispatch(actions.addressFetchRequested(filter, pagination, includeInactive))
  }, [])

  return (
    <>
      <Container>
        {props.addressReducer.alertMsg && (
          <Alert
            severity={props.addressReducer.alertSeverity || 'info'}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => props.dispatch(actions.clearMsg())}>
                <CloseIcon />
              </Button>
            }>
            {t(props.addressReducer.alertMsg)}
          </Alert>
        )}
        <div className={classes.pageTitle}>
          <h3>{t('location.title')}</h3>
          <div className={classes.btnContainer}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.btn}
              onClick={() => history.push('/locatie/detail')}>
              {t('location.addLocation')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.btn}
              onClick={_onImportClicked}>
              {t('location.importLocation')}
            </Button>
            <Button
              href={process.env.PUBLIC_URL + '/templates/Locatie.xlsx'}
              variant="outlined"
              color="primary"
              size="small"
              className={classes.btn}>
              {t('location.downloadTemplate')}
            </Button>
          </div>
        </div>
        <Grid container spacing={0}>
          <Grid item md={9}>
            <AddressFilter onSearch={_onSearch} />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item md={12}>
            <FormControlLabel
              control={
                <Switch checked={includeInactive} onChange={_onSwitchToggled} color="primary" />
              }
              className={classes.switch}
              label={t('location.showDeletedLocation')}
            />

            <AddressTable
              rows={props.addressReducer.listAddress}
              total={props.addressReducer.totalAddress}
              onDeleteAddress={_onDeletePressed}
              onEditAddress={_onEditAddress}
              onRestoreAddress={_onRestoreAddress}
              onChangePagination={_onChangePagination}
            />
          </Grid>
        </Grid>
        <DeleteWarningDialog
          isDialogShown={isDeleteDialogShown}
          onDeleteAddress={_doDeleteAddress}
          onCancelDelete={() => showDeleteDialog(false)}
        />

        <ImportAddressDialog
          isDialogShown={isImportDialogShown}
          onImportClicked={file => {
            showImportDialog(false)
            if (file.size > 0) {
              props.dispatch(
                actions.addressUploadRequested(file, history, filter, pagination, includeInactive)
              )
            } else {
              props.dispatch(actions.addressUploadEmpty())
            }
          }}
          onClose={() => showImportDialog(false)}
        />
        <LoadingIndicator open={props.addressReducer.isLoading} />
      </Container>
    </>
  )
}

AddressScreen.propTypes = {
  addressReducer: PropTypes.object
}

DeleteWarningDialog.propTypes = {
  isDialogShown: PropTypes.bool.isRequired,
  onDeleteAddress: PropTypes.func.isRequired,
  onCancelDelete: PropTypes.func.isRequired
}

ImportAddressDialog.propTypes = {
  isDialogShown: PropTypes.bool.isRequired,
  onImportClicked: PropTypes.func.isRequired,
  onClose: PropTypes.func
}

const mapStateToProps = state => {
  const {addressReducer} = state
  return {addressReducer}
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => dispatch(action)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressScreen)
