const batteryLevelEnum = {
  UNKNOWN: 'UNKNOWN',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH'
}

enum DeviceType {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

enum UserRole {
  ADMIN = 'ROLE_ADMIN',
  CLIENT_ADMIN = 'ROLE_WEBUSER',
  USER = 'ROLE_USER',
  TEAM_MANAGER = 'ROLE_TEAMMANAGER',
  DAR = 'ROLE_DAR'
}

enum EmployeeFilterField {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  EMAIL = 'email',
  STATUS = 'status',
  TEAM = 'team',
  LASTTIMEONLINE = 'lastTimeOnline',
  BATTERY = 'battery'
}

export {batteryLevelEnum, DeviceType, EmployeeFilterField, UserRole}
