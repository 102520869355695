import React from 'react'
import TextField from '@material-ui/core/TextField'

const TextFieldAdapter = ({
  input: {name, onChange, value, ...restInput},
  meta,
  maxLength,
  ...rest
}) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
  let inputValue = value
  if (maxLength && value.length > maxLength) {
    inputValue = value.slice(0, maxLength)
  }
  return (
    <TextField
      {...rest}
      name={name}
      // variant="outlined"
      size="small"
      fullWidth
      inputProps={{maxLength: maxLength, ...restInput}}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
      value={inputValue}
      onChange={onChange}
      // InputProps={{style: {backgroundColor: 'white'}}}
    />
  )
}

export default TextFieldAdapter
