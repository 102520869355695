import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import {makeStyles} from '@material-ui/core/styles'

import Colors from '../../commons/constants/colors'
import Sizes from '../../commons/constants/sizes'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles({
  actionButton: {
    textAlign: 'right'
  },
  row: {
    color: '#848484'
  },
  primaryTitle: {
    backgroundColor: '#3f51b5',
    color: 'white',
    padding: '1rem 0.75rem',
    margin: 0
  },
  containedTitle: {
    backgroundColor: '#F5F6FA',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  label: {
    fontWeight: 'bold'
  },
  plainTitle: {
    backgroundColor: 'white',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  table: {
    backgroundColor: 'white'
  },
  tableHead: {
    backgroundColor: Colors.lightGray,
    borderTop: '1px solid',
    borderTopColor: Colors.gray,
    borderBottom: '2px solid',
    borderBottomColor: Colors.gray
  },
  tableHeadCell: {
    color: Colors.primary,
    fontWeight: 'bold'
  },
  tCellIcon: {
    width: '35px',
    textAlign: 'center',
    paddingLeft: Sizes.defaultPadding,
    paddingRight: Sizes.defaultPadding
  },
  tRowDetail: {
    backgroundColor: Colors.background
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    color: Colors.text,
    fontSize: '1rem'
  }
})

const EmployeeTableRow = props => {
  const classes = useStyles()

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell>
          <Typography className={classes.wrapIcon}>{props.team.name}</Typography>
        </TableCell>
        <TableCell className={classes.actionButton}>
          <IconButton onClick={() => props.onEditTeam(props.team.teamId)}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => props.onDeleteTeam(props.team.teamId)}>
            <DeleteOutlineIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  )
}

EmployeeTableRow.propTypes = {
  team: PropTypes.object,
  onEditTeam: PropTypes.func.isRequired,
  onDeleteTeam: PropTypes.func.isRequired
}

export default EmployeeTableRow
