import React, {useState} from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  icon: {color: 'red'},
  iconHover: {
    color: 'blue'
  }
})

const imageOffset = {
  left: 12,
  top: 24
}

const MapMarker = props => {
  const [hover, setHover] = useState(false)
  const classes = useStyles()
  let eventParameters = event => ({event, anchor: props.anchor, payload: props.payload})

  const _handleMouseOver = event => {
    props.onMouseOver && props.onMouseOver(eventParameters(event))
    setHover(true)
  }

  const _handleMouseOut = event => {
    props.onMouseOut && props.onMouseOut(eventParameters(event))
    setHover(false)
  }

  const _handleClick = event => {
    props.onClick && props.onClick(eventParameters(event))
  }

  const style = {
    position: 'absolute',
    transform: `translate(${props.left - imageOffset.left}px, ${props.top - imageOffset.top}px)`,
    cursor: props.onClick ? 'pointer' : 'default'
  }
  return (
    <div
      style={style}
      className="pigeon-click-block"
      onClick={_handleClick}
      // onContextMenu={this.handleContextMenu}
      onMouseOver={_handleMouseOver}
      onMouseOut={_handleMouseOut}>
      <SvgIcon {...props} fontSize="large">
        <path
          fill="currentColor"
          d="M6 9c0 3.54 3.82 8.86 6 11.47 1.75-2.11 6-7.63 6-11.47 0-3.31-2.69-6-6-6S6 5.69 6 9zm9 2.5v.12c-.73.84-1.8 1.38-3 1.38s-2.27-.54-3-1.38v-.12c0-1 2-1.5 3-1.5s3 .5 3 1.5zm-1.5-4c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5S11.17 6 12 6s1.5.67 1.5 1.5z"
        />
        <path
          fill="red"
          d="M4 9c0 5.57 6.96 13.34 7.26 13.67l.74.82.74-.82C13.04 22.34 20 14.57 20 9c0-4.41-3.59-8-8-8S4 4.59 4 9zm14 0c0 3.83-4.25 9.36-6 11.47C9.82 17.86 6 12.54 6 9c0-3.31 2.69-6 6-6s6 2.69 6 6zm-7.5-1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5S12.83 6 12 6s-1.5.67-1.5 1.5zm-1.5 4v.12c.73.84 1.8 1.38 3 1.38s2.27-.54 3-1.38v-.12c0-1-2-1.5-3-1.5s-3 .5-3 1.5z"></path>
      </SvgIcon>
    </div>
  )
}

MapMarker.propTypes = {
  // input, passed to events
  anchor: PropTypes.array.isRequired,
  payload: PropTypes.any,

  // optional modifiers
  hover: PropTypes.bool,

  // callbacks
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,

  // pigeon variables
  left: PropTypes.number,
  top: PropTypes.number,

  // pigeon functions
  latLngToPixel: PropTypes.func,
  pixelToLatLng: PropTypes.func
}

export default MapMarker
