import axios from 'axios'
import Constants from '../commons/constants/constants'

const instance = axios.create()

const BASE_URL = process.env.REACT_APP_SERVER_URL

const RestRequester = {
  login: async function (username, password) {
    const clientId = sessionStorage.getItem('clientId')

    return instance.post(
      `${BASE_URL}${clientId}/v2/api/web/login`,
      {
        username: username,
        password: password
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  },
  logout: async function () {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')

    return instance.post(
      `${BASE_URL}${clientId}/v2/api/employee/logout`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      }
    )
  },
  getEmployee: async function (employeeId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.get(`${BASE_URL}${clientId}/v2/api/employee/detail/${employeeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  saveEmployee: async function (employee) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.post(`${BASE_URL}${clientId}/v2/api/employee/`, employee, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  deleteEmployee: async function (employeeId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.delete(`${BASE_URL}${clientId}/v2/api/employee/${employeeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  updateEmployee: async function (employee) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.put(
      `${BASE_URL}${clientId}/v2/api/employee/detail/${employee.employeeId}`,
      employee,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      }
    )
  },
  getEmployeeList: async function (pageable, filter, isDAR = false) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const pagination = pageable || Constants.defaultEmployeePagination
    let data = new FormData()
    data.append('isDAR', isDAR)
    data.append('draw', pagination.draw)
    data.append('page', pagination.page)
    data.append('size', pagination.size)
    data.append('sort', pagination.sort)

    if (filter && filter.firstName) {
      data.append('firstName', filter.firstName)
    }

    if (filter && filter.lastName) {
      data.append('lastName', filter.lastName)
    }

    if (filter && filter.teams && filter.teams.teamId) {
      // data.append('teams[0]', JSON.stringify(filter.teams))
      data.append('teams[0].teamId', filter.teams.teamId)
    }

    if (filter && filter.email) {
      data.append('email', filter.email)
    }

    if (filter && filter.lastTimeOnline) {
      data.append('lastTimeOnline', filter.lastTimeOnline)
    }

    if (filter && filter.status) {
      data.append('status', filter.status)
    }

    if (filter && filter.batteryLevel) {
      data.append('batteryLevel', filter.batteryLevel)
    }

    return instance.post(`${BASE_URL}${clientId}/v2/api/employee/list`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  uploadEmployeeList: function (file) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    let data = new FormData()
    data.append('file', file)
    return instance.post(`${BASE_URL}${clientId}/v2/api/employee/upload`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  getMyDetail: function () {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.get(`${BASE_URL}${clientId}/v2/api/auth/me`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  changePassword: function (oldPass, newPass) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.post(
      `${BASE_URL}${clientId}/v2/api/employee/changePassword`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        params: {
          oldPass,
          newPass
        }
      }
    )
  },
  resetPassword: async function (employeeId, adminPassword) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.get(
      `${BASE_URL}${clientId}/v2/api/employee/reset-password/${employeeId}/${adminPassword}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      }
    )
  },
  requestResetToken: async function (clientId, email) {
    // const clientId = sessionStorage.getItem('clientId')
    return instance.get(`${BASE_URL}${clientId}/v2/api/employee/reset-token/${email}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  resetPasswordByToken: async function (token, clientId, password) {
    // const clientId = sessionStorage.getItem('clientId')
    const data = {
      resetToken: token,
      password: password
    }
    return instance.post(`${BASE_URL}${clientId}/v2/api/employee/reset-password-by-token/`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  validateResetToken: function (token, clientId) {
    // const clientId = sessionStorage.getItem('clientId')
    if (!(clientId && token)) throw 'Client id or token is null!'
    return instance.get(`${BASE_URL}${clientId}/v2/api/employee/validate-token/${token}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  getLiveStatus: async function (teamId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    let url = `${BASE_URL}${clientId}/v2/api/report/livestatus`
    if (teamId) {
      url += '?teamId=' + teamId
    }
    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  setInterceptor: function (token) {
    instance.interceptors.request.use((config) => {
      if (token) {
        config.headers.authorization = token
      }
      return config
    })
  },
  getTeamList: async function () {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.get(`${BASE_URL}${clientId}/v2/api/teams`, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getTeamListPaged: async function (pageable, filter) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const pagination = pageable || Constants.defaultTeamPagination
    let data = new FormData()
    data.append('draw', pagination.draw)
    data.append('page', pagination.page)
    data.append('size', pagination.size)
    data.append('sort', pagination.sort)

    if (filter && filter.name) {
      data.append('teamName', filter.name)
    }
    return instance.post(`${BASE_URL}${clientId}/v2/api/teams/list`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getTeamDetail: async function (teamId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.get(`${BASE_URL}${clientId}/v2/api/teams/${teamId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  insertTeamDetail: async function (team) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.post(`${BASE_URL}${clientId}/v2/api/teams/`, team, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  updateTeamDetail: async function (team) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const teamId = team.teamId
    return instance.put(`${BASE_URL}${clientId}/v2/api/teams/${teamId}`, team, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  deleteTeam: async function (teamId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.delete(`${BASE_URL}${clientId}/v2/api/teams/${teamId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  getAppointmentAndAlarmReport: async function (startDate, endDate, teamId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    let url = `${BASE_URL}${clientId}/v2/api/report/appointment-and-alarm`
    if (startDate && endDate) {
      url += '?startDate=' + startDate + '&endDate=' + endDate
    }
    if (teamId) {
      url += '&teamId=' + teamId
    }
    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getTeamReport: async function (startDate, endDate) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    let url = `${BASE_URL}${clientId}/v2/api/report/team-summary`
    if (startDate && endDate) {
      url += '?startDate=' + startDate + '&endDate=' + endDate
    }
    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getAlarmTrend: async function (teamId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    let url = `${BASE_URL}${clientId}/v2/api/report/alarm-trend`
    if (teamId) {
      url += '?teamId=' + teamId
    }

    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  exportReport: function (docType, startDate, endDate, teamId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    let url = `${BASE_URL}${clientId}/v2/api/report/export`
    if (docType) {
      url += '?reportType=' + docType
    }
    if (startDate && endDate) {
      url += '&startDate=' + startDate + '&endDate=' + endDate
    }
    if (teamId) {
      url += `&teamId=${teamId}`
    }

    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      },
      responseType: 'blob'
    })
  },
  exportAppointmentList: function (startDate, endDate, teamId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    let url = `${BASE_URL}${clientId}/v2/api/report/export-appointment`

    if (startDate && endDate) {
      url += '?startDate=' + startDate + '&endDate=' + endDate
    }

    if (teamId) {
      url += '&teamId=' + teamId
    }
    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      },
      responseType: 'blob'
    })
  },
  downloadApp: function (platform) {
    return instance.get(`${BASE_URL}admin/v2/api/application/download/${platform}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/json'
        // Authorization: token
      }
    })
  },
  getAppointmentsWithAlarm: function () {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.get(`${BASE_URL}${clientId}/v2/api/appointment`, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getAddress: async function (addressId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.get(`${BASE_URL}${clientId}/v2/api/address/${addressId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getAddressList: function (pageable, filter, includeHidden) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const pagination = pageable || Constants.defaultAddressPagination
    let data = new FormData()
    data.append('draw', pagination.draw)
    data.append('page', pagination.page)
    data.append('size', pagination.size)
    data.append('sort', pagination.sort)

    if (filter && filter.address) {
      data.append('address', filter.address)
    }

    if (filter && filter.additionalInfo) {
      data.append('additionalInfo', filter.additionalInfo)
    }
    data.append('includeHidden', Boolean(includeHidden))

    return instance.post(`${BASE_URL}${clientId}/v2/api/address/list`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  insertAddressDetail: async function (address) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.post(`${BASE_URL}${clientId}/v2/api/address/`, address, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  updateAddressDetail: async function (address) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const addressId = address.id

    return instance.put(`${BASE_URL}${clientId}/v2/api/address/${addressId}`, address, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  deleteAddressDetail: async function (addressId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.delete(`${BASE_URL}${clientId}/v2/api/address/${addressId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  uploadAddressList: function (file) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    let data = new FormData()
    data.append('file', file)
    return instance.post(`${BASE_URL}${clientId}/v2/api/address/upload`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  getBatteryOfEmployee: function (employeeId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    return instance.get(`${BASE_URL}${clientId}/v2/api/button/employee/${employeeId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getOnlineSummary: function (startDate, endDate, teamId) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    let url = `${BASE_URL}${clientId}/v2/api/report/online-summary`
    if (startDate && endDate) {
      url += '?startDate=' + startDate + '&endDate=' + endDate
    }
    if (teamId) {
      url += '&teamId=' + teamId
    }
    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getFirstOnlineRecordDate: function () {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const url = `${BASE_URL}${clientId}/v2/api/report/first-online-summary-date`

    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  addReleaseNote: function (releaseDTO) {
    const token = sessionStorage.getItem('token')
    const url = `${BASE_URL}admin/v2/api/release`

    return instance.post(url, releaseDTO, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  updateReleaseNote: function (releaseDTO, releaseId) {
    const token = sessionStorage.getItem('token')
    const url = `${BASE_URL}admin/v2/api/release/${releaseId}`

    return instance.put(url, releaseDTO, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getReleaseNote: function (releaseId) {
    const token = sessionStorage.getItem('token')
    const url = `${BASE_URL}admin/v2/api/release/${releaseId}`

    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  listReleaseNote: function () {
    const token = sessionStorage.getItem('token')
    const url = `${BASE_URL}admin/v2/api/release/list`

    return instance.post(
      url,
      {},
      {
        headers: {
          Accept: 'application/json',
          Authorization: token
        }
      }
    )
  },
  deleteReleaseNote: async function (noteId) {
    const token = sessionStorage.getItem('token')

    return instance.delete(`${BASE_URL}admin/v2/api/release/${noteId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  },
  uploadMobileApp: function (mobileApp, file, progressHandler) {
    const token = sessionStorage.getItem('token')
    let url = `${BASE_URL}admin/v2/api/application/upload`
    if (mobileApp.id) url += `/${mobileApp.id}`

    let data = new FormData()
    // data.append('app', JSON.stringify(mobileApp))
    data.append('version', mobileApp.release.version)
    data.append('platform', mobileApp.platform)
    data.append('file', file)

    return instance.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true,
        Authorization: token
      },
      onUploadProgress: progressHandler
    })
  },
  getAppByVersion: function (version) {
    const token = sessionStorage.getItem('token')
    const url = `${BASE_URL}admin/v2/api/application/version/${version}`

    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  deleteApp: function (id) {
    const token = sessionStorage.getItem('token')
    const url = `${BASE_URL}admin/v2/api/application/${id}`

    return instance.delete(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getOrgSettings: function () {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const url = `${BASE_URL}${clientId}/v2/api/settings/org-settings`

    return instance.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  saveOrgName: function (name) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const url = `${BASE_URL}${clientId}/v2/api/settings/org-name/${name}`

    return instance.post(
      url,
      {},
      {
        headers: {
          Accept: 'application/json',
          Authorization: token
        }
      }
    )
  },
  saveOrgLogo: function (logo, progressHandler) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const url = `${BASE_URL}${clientId}/v2/api/settings/org-logo`
    let data = new FormData()
    data.append('logo', logo)
    return instance.post(url, data, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      },
      onUploadProgress: progressHandler
    })
  },
  deleteOrgName: function () {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const url = `${BASE_URL}${clientId}/v2/api/settings/org-name`

    return instance.delete(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  deleteOrgLogo: function () {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const url = `${BASE_URL}${clientId}/v2/api/settings/org-logo`

    return instance.delete(url, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  updateAlarm112Settings: function (isDisabled) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const url = `${BASE_URL}${clientId}/v2/api/settings/alarm-112/${isDisabled}`

    return instance.post(url, null, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
  },
  getReceiverList: async function (pageable, filter) {
    const token = sessionStorage.getItem('token')
    const clientId = sessionStorage.getItem('clientId')
    const pagination = pageable || Constants.defaultEmployeePagination
    let data = new FormData()
    data.append('draw', pagination.draw)
    data.append('page', pagination.page)
    data.append('size', pagination.size)
    data.append('sort', pagination.sort)

    if (filter) {
      if (filter.firstName) {
        data.append('firstName', filter.firstName)
      }

      if (filter.lastName) {
        data.append('lastName', filter.lastName)
      }

      if (filter.team && filter.team.teamId) {
        data.append('team.teamId', filter.team.teamId)
      }

      if (filter.email) {
        data.append('email', filter.email)
      }

      if (filter.lastTimeOnline) {
        data.append('lastTimeOnline', filter.lastTimeOnline)
      }

      if (filter.status) {
        data.append('status', filter.status)
      }

      if (filter.batteryLevel) {
        data.append('batteryLevel', filter.batteryLevel)
      }
    }

    return instance.post(`${BASE_URL}${clientId}/v2/api/employee/list`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  }
}

export default RestRequester
