import {all, call, put, takeLatest} from 'redux-saga/effects'
import RestRequester from '../../api/RestRequester'
import * as types from './types'
import * as actions from './actions'

function* fetchAddressList(action) {
  try {
    const response = yield call(() =>
      RestRequester.getAddressList(
        action.payload.pagination,
        action.payload.filter,
        action.payload.includeHidden
      )
    )
    yield put(actions.addressFetchSucceed(response.data))
  } catch (error) {
    yield put(actions.addressFetchFailed(error.response.data))
  }
}

function* fetchAddressDetail(action) {
  try {
    const response = yield call(() => RestRequester.getAddress(action.payload.addressId))
    yield put(actions.addressDetailFetchSucceed(response.data))
  } catch (error) {
    yield put(actions.addressDetailFetchFailed(error.response.data))
  }
}

function* insertAddressDetail(action) {
  try {
    yield call(() => RestRequester.insertAddressDetail(action.payload.address))
    const history = action.payload.history
    yield put(actions.addressInsertSucceed(history))
  } catch (error) {
    yield put(actions.addressInsertFailed(error.response.data))
  }
}

function* insertAddressSuccess(action) {
  yield call(() => action.payload.history.goBack())
}

function* updateAddressDetail(action) {
  try {
    yield call(() => RestRequester.updateAddressDetail(action.payload.address))
    const history = action.payload.history
    yield put(actions.addressUpdateSucceed(history))
  } catch (error) {
    yield put(actions.addressUpdateFailed(error.response.data))
  }
}

function* updateAddressSuccess(action) {
  if (action.payload.history) {
    yield call(() => action.payload.history.goBack())
  }
}

function* deleteAddressDetail(action) {
  try {
    yield call(() => RestRequester.deleteAddressDetail(action.payload.address.id))
    yield put(
      actions.addressDeleteSucceed(
        action.payload.filter,
        action.payload.pagination,
        !action.payload.address.active
      )
    )
  } catch (error) {
    yield put(actions.addressDeleteFailed(error.response.data))
  }
}

function* deleteAddressSucceed(action) {
  yield put(
    actions.addressFetchRequested(
      action.payload.filter,
      action.payload.pagination,
      action.payload.includeHidden
    )
  )
}

function* uploadAddressList(action) {
  try {
    const response = yield call(() => RestRequester.uploadAddressList(action.payload.file))
    yield put(
      actions.addressUploadSucceeded(
        response.data,
        action.payload.history,
        action.payload.filter,
        action.payload.pagination,
        action.payload.includeHidden
      )
    )
  } catch (error) {
    console.error(error.response)
    yield put(actions.addressUploadFailed(error.response.data))
  }
}

function* uploadAddressSuccess(action) {
  const history = action.payload.history
  if (history.location.pathname !== '/locatie') {
    yield call(() => history.goBack())
  } else {
    yield put(
      actions.addressFetchRequested(
        action.payload.filter,
        action.payload.pagination,
        action.payload.includeHidden
      )
    )
  }
}

function* saga() {
  yield all([
    takeLatest(types.ADDRESS_FETCH_REQUESTED, fetchAddressList),
    takeLatest(types.ADDRESS_INSERT_REQUESTED, insertAddressDetail),
    takeLatest(types.ADDRESS_INSERT_SUCCEEDED, insertAddressSuccess),
    takeLatest(types.ADDRESS_DETAIL_FETCH_REQUESTED, fetchAddressDetail),
    takeLatest(types.ADDRESS_UPDATE_REQUESTED, updateAddressDetail),
    takeLatest(types.ADDRESS_UPDATE_SUCCEEDED, updateAddressSuccess),
    takeLatest(types.ADDRESS_DELETE_REQUESTED, deleteAddressDetail),
    takeLatest(types.ADDRESS_DELETE_SUCCEEDED, deleteAddressSucceed),
    takeLatest(types.ADDRESS_UPLOAD_REQUESTED, uploadAddressList),
    takeLatest(types.ADDRESS_UPLOAD_SUCCEEDED, uploadAddressSuccess)
  ])
}

export default saga
