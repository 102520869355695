import React, {useEffect, useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {makeStyles} from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {Form} from 'react-final-form'
import {useTranslation} from 'react-i18next'
import {TextField as TextFieldWrapper, Checkboxes} from 'mui-rff'

import {Dialog} from '../../components/Dialog'
import LoadingIndicator from '../../components/LoadingIndicator'
import Constants from '../../commons/constants/constants'
import TeamTable from './TeamTable'
import * as actions from './actions'
import sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import {required} from '../../commons/validator'
import {hasRole} from '../../commons/utils'
import {UserRole} from '../../commons/enums'

const useStyles = makeStyles({
  btn: {
    marginRight: '0.5rem'
  },
  btnContainer: {
    alignSelf: 'center'
  },
  btnText: {
    textTransform: 'lowercase',
    color: '#C7C7C7'
  },
  btnAddTeam: {
    margin: '0.5rem'
  },
  detailContainer: {
    paddingLeft: sizes.defaultPadding,
    paddingRight: sizes.defaultPadding,
    paddingTop: '38px'
  },
  detailTitle: {
    padding: sizes.defaultPadding,
    color: 'white',
    backgroundColor: Colors.primary
  },
  detailInput: {
    // backgroundColor: 'white',
    color: Colors.textLight
  },
  filterContainer: {
    backgroundColor: '#FFFFFF',
    padding: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'flex'
  },
  grid: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  pageTitle: {
    display: 'flex'
  },
  select: {
    margin: '0.5rem',
    minWidth: '300px',
    color: '#C7C7C7',
    display: 'inline-block'
  },
  tableContainer: {
    padding: '1rem'
  }
})

const TeamFilter = (props) => {
  const classes = useStyles()
  const {t, i18n} = useTranslation()
  return (
    <div className={classes.filterContainer}>
      <Autocomplete
        id="team-filter"
        classes={{root: classes.select}}
        options={props.teamList}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          props.onChange(value)
        }}
        value={props.selectedTeam}
        size="small"
        renderInput={(params) => (
          <TextField {...params} label={t('team.teamName')} variant="outlined" fullWidth />
        )}
      />
      <Button
        id="_report_WAR_antesportlet_filter-date-selector"
        classes={{text: classes.btnText}}
        className={classes.btnAddTeam}
        onClick={() => props.onAddClicked()}
        endIcon={<AddCircleIcon color="primary" />}>
        {t('team.addTeam')}
      </Button>
    </div>
  )
}

TeamFilter.propTypes = {
  teamList: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  selectedTeam: PropTypes.object,
  onAddClicked: PropTypes.func.isRequired
}
const emptyDialog = {isShown: false, msg: '', onConfirm: () => {}, onCancel: () => {}}

const TeamScreen = (props) => {
  const classes = useStyles()
  const {t, i18n} = useTranslation()

  const [pagination, setPagination] = useState(Constants.defaultTeamPagination)
  const [isDetailShown, showDetail] = useState(false)
  const [filter, setFilter] = useState()
  const [dialogProps, setDialogProps] = useState(emptyDialog)

  useEffect(() => {
    document.title = 'SAFE | Team'
    props.dispatch(actions.fetchTeamsPagedRequested(pagination, filter))

    return function cleanup() {
      props.dispatch(actions.clearTeamDetail())
    }
  }, [filter, pagination])

  useEffect(() => {
    props.dispatch(actions.fetchTeamsRequested())
  }, [])

  const _onDeletePressed = (teamId) => {
    const dialog = {
      isShown: true,
      msg: t('team.deleteConfirmation'),
      onConfirm: () => {
        props.dispatch(actions.deleteTeamsDetailRequested(teamId, pagination, filter))
        setDialogProps(emptyDialog)
      },
      onCancel: () => {
        setDialogProps(emptyDialog)
      }
    }
    setDialogProps(dialog)
  }

  const _onShowTeamDetail = (teamId) => {
    props.dispatch(actions.clearTeamDetail())
    showDetail(true)
    if (teamId) {
      props.dispatch(actions.fetchTeamsDetailRequested(teamId))
    }
  }

  const _onChangePagination = (changedPagination) => {
    setPagination(changedPagination)
  }

  const _onBackPressed = () => {
    showDetail(false)
    props.dispatch(actions.clearTeamDetail())
  }
  const _onSaveTeam = (values, form) => {
    console.log('saving team', values)
    const detailTeam = {
      ...props.teamReducer.detailTeam,
      name: values.teamName,
      testCase: values.testCase
    }
    showDetail(false)
    props.dispatch(actions.saveTeamsDetailRequested(detailTeam, pagination, filter, form))
  }

  const _confirmDeleteDialog = () => {}
  const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser'))
  return (
    <>
      <Container>
        <div className={classes.pageTitle}>
          <h3>{t('team.title')}</h3>
        </div>

        <Grid container spacing={0}>
          <Grid item md={12}>
            <TeamFilter
              teamList={props.teamReducer.listTeam}
              onChange={setFilter}
              selectedTeam={filter}
              onAddClicked={_onShowTeamDetail}
            />
          </Grid>
          <Grid item md={8}>
            {props.teamReducer.errorMsg && (
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => props.dispatch(actions.clearErrorMsg())}>
                    {t('common.ok')}
                  </Button>
                }>
                {t(props.teamReducer.errorMsg)}
              </Alert>
            )}
            <TeamTable
              rows={props.teamReducer.pagedTeams}
              total={props.teamReducer.totalTeam}
              onDeleteTeam={_onDeletePressed}
              onEditTeam={_onShowTeamDetail}
              onChangePagination={_onChangePagination}
            />
          </Grid>
          <Grow in={isDetailShown} style={{transformOrigin: '0 0 0'}}>
            <Grid item md={4}>
              <h4></h4>
              <div className={classes.detailContainer}>
                <Form
                  reducer={props.teamReducer}
                  onSubmit={_onSaveTeam}
                  initialValues={{
                    teamName: props.teamReducer.detailTeam.name,
                    testCase: props.teamReducer.detailTeam.testCase
                  }}
                  render={(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <Typography variant="h5" className={classes.detailTitle}>
                            {props.reducer.detailTeam.teamId
                              ? t('team.editTeam')
                              : t('team.addTeam')}
                          </Typography>
                        </Grid>
                        <Grid item md={12}>
                          <TextFieldWrapper
                            name="teamName"
                            id="teamName"
                            label={t('team.teamName')}
                            className={classes.detailInput}
                            inputProps={{style: {backgroundColor: 'white'}}}
                            variant="outlined"
                            size="small"
                            fullWidth
                            validate={(value) => required(value, i18n)}
                          />
                        </Grid>
                        {loggedInUser && hasRole(loggedInUser.systemRoles, UserRole.ADMIN) && (
                          <Grid item md={12}>
                            <Checkboxes
                              name="testCase"
                              data={{
                                label: t('common.markTestCase'),
                                value: props.reducer.detailTeam.testCase
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <Button
                            disabled={props.submitting}
                            variant="contained"
                            color="primary"
                            size="medium"
                            className={classes.btn}
                            type="submit">
                            {t('common.save')}
                          </Button>
                          <Button
                            disabled={props.submitting}
                            variant="outlined"
                            color="primary"
                            size="medium"
                            className={classes.btn}
                            onClick={_onBackPressed}>
                            {t('common.cancel')}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                />
              </div>
            </Grid>
          </Grow>
        </Grid>
      </Container>
      <Dialog
        isShown={dialogProps.isShown}
        msg={dialogProps.msg}
        onConfirm={dialogProps.onConfirm}
        onCancel={dialogProps.onCancel}
      />
      <LoadingIndicator open={props.teamReducer.isLoading} />
    </>
  )
}

const mapStateToProps = (state) => {
  const {teamReducer} = state
  return {teamReducer}
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => dispatch(action)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TeamScreen)
