import React, {useState} from 'react'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import AppleIcon from '@material-ui/icons/Apple'
import AndroidIcon from '@material-ui/icons/Android'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'

import Sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import { useTranslation } from 'react-i18next'
import { DeviceType } from '../../commons/enums'
import { MobileApplication } from './types'

export interface Props {
  app: any,
  fileType: DeviceType,
  onUpload: (file: File)=>void,
  onDelete: (app: MobileApplication) => void
}

const useStyles = makeStyles({
  btn: {
    marginLeft: Sizes.largeMargin
  },
  error: {
    color: Colors.alert,
    fontSize: '.75rem',
    marginLeft: Sizes.defaultMargin
  },
  iconContainer: {
    display: 'inline-block',
    marginLeft: Sizes.largeMargin
  },
  paper: {
    display: 'inline-flex',
    marginTop: Sizes.defaultMargin,
    marginBottom: Sizes.defaultMargin,
    marginRight: Sizes.defaultMargin,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: Sizes.largeMargin,
    paddingBottom: Sizes.largeMargin,
    width: '100%'
  },
  paperContainer: {
    flexDirection: "row"
  },
  root:{
    flexDirection: 'column'
  },
  uploadIcon: {
    position: 'absolute',
    zIndex: 3,
    padding: '.75rem',
    color: Colors.primaryDarkest,
    fontSize: '1.3rem',
    right: 0
  },
  uploadIconDisabled: {
    position: 'absolute',
    zIndex: 3,
    padding: '.75rem',
    color: Colors.text,
    fontSize: '1.3rem',
    right: 0
  },
  uploadInput: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
    margin: 0,
    opacity: 0,
    height: 'calc(2.5rem + 2px)'
  },
  uploadDisabled: {    
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    color: Colors.textLight,
    padding: '.75rem'
  },
  uploadLbl: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    color: Colors.text,
    padding: '.75rem'
  },
  uploadContainer: {
    position: 'relative',
    display: 'inline-block',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    height: 'calc(2.5rem + 2px)',
    borderBottom: `1px solid ${Colors.textLight}`
  },
})

const MobileUploadButton: React.FC<Props> = (props) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState<string>('')
  const [editable, setEditable] = useState<boolean>(false)

  const acceptedFile: string = props.fileType === DeviceType.ANDROID ? 
    'application/vnd.android.package-archive' : 'application/zip'

  const _onUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const input:HTMLInputElement = event.target
    const files = input.files
    
    if (files !== null) {
      const file: File = files[0]
      if (file.type === acceptedFile) {
        setFile(file)
      } else {
        if(props.fileType === DeviceType.IOS) {
          setError('File type is not valid, use .zip file.')
        } else {
          setError('File type is not valid, use .apk file.')
        }
      }
    }
  }

  return (
    <div className={classes.root}>
    <Paper className={classes.paper}>
      <div className={classes.iconContainer}>
        {
          props.fileType === DeviceType.ANDROID ? 
            <AndroidIcon /> : <AppleIcon />
        } 
      </div>
      <div className={classes.uploadContainer}>
        <input
          disabled = {!(props.app.id === null || props.app.id === undefined) && !editable}
          accept={acceptedFile}
          className={classes.uploadInput}
          id="contained-button-file"
          type="file"
          onChange={_onUploadChange}
        />
        <label htmlFor="contained-button-file" className={(props.app.id !== null && props.app.id !== undefined ) ? classes.uploadDisabled :classes.uploadLbl}>
          {(props.app.id === null || props.app.id === undefined) || editable ? 
              file === null ? t('common.chooseFile') : file.name 
              : t('release.appUploaded')}
              
        </label>
        <AddCircleIcon className={!editable ? classes.uploadIconDisabled : classes.uploadIcon} />
      </div>
      <Button
        id="btnUpload"
        disabled = {!(props.app.id === null || props.app.id === undefined) && !editable}
        variant="contained"
        color="primary"
        size="small"
        className={classes.btn}
        onClick={() => {
          if (file !== null) {
            props.onUpload(file)
          }
        }}>
        {t('release.upload')}
      </Button>
      <IconButton 
        id="btnEdit"
        color="primary" 
        aria-label="change" 
        component="span" 
        onClick={() => setEditable(true)} 
        disabled={(props.app.id === null || props.app.id === undefined) || editable}>
        <Edit />
      </IconButton>
      <IconButton 
        id="btnDelete"
        color="primary" 
        aria-label="delete" 
        component="span" 
        onClick={() => props.onDelete(props.app)} 
        disabled={(props.app.id === null || props.app.id === undefined )}>
        <Delete />
      </IconButton>
    </Paper>
    <Typography id="errorMsg" className={classes.error}>{error}</Typography>
    </div>
  )
}

export default MobileUploadButton