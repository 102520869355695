const hasRole = (userRoles, role) => {
  if (userRoles == null && !Array.isArray(userRoles)) {
    throw 'input parameter is not an array'
  }

  return (
    userRoles.findIndex((value) => {
      return value.name === role
    }) >= 0
  )
}

export {hasRole}
