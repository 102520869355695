import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import TextField from '@material-ui/core/TextField'
// import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import {Autocomplete, Select, TextField} from 'mui-rff'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Form, FormRenderProps} from 'react-final-form'

import Colors from '../../commons/constants/colors'
import Sizes from '../../commons/constants/sizes'
import Constants from '../../commons/constants/constants'
import {batteryLevelEnum} from '../../commons/enums'
import {EmployeeFilterType, Team} from '../../commons/interfaces'
import {EmployeeFilterField} from '../../commons/enums'

const useStyles = makeStyles({
  btn: {
    marginRight: Sizes.defaultMargin
  },
  titleContainer: {
    backgroundColor: Colors.primary,
    color: 'white',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    justifyContent: 'flex-start'
  },
  contentSummaryContainer: {
    flexGrow: 0
  },
  detailContainer: {
    backgroundColor: Colors.background,
    paddingLeft: 0,
    paddingRight: 0
  },
  heading: {
    fontSize: '1.5rem'
  },
  inputField: {
    backgroundColor: 'white',
    color: Colors.textLight,
    borderColor: 'white'
  }
})

export interface Props {
  teams: Array<Team>
  onSearch: (filter: EmployeeFilterType) => void
  excludedFields?: Array<string>
}

interface FormData {
  firstName?: string
  lastName?: string
  email?: string
  status?: number
  teams?: string
  lastTimeOnline: number
  batteryLevel: number
}

const EmployeeFilter: React.FC<Props> = (props) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const [isTeamCleared, setTeamCleared] = useState<boolean>(false)

  const _isNotExcluded = (field: EmployeeFilterField) => {
    return props.excludedFields == undefined || !props.excludedFields.includes(field)
  }

  const initialValue: FormData = {
    firstName: '',
    lastName: '',
    email: '',
    status: 0,
    teams: '',
    lastTimeOnline: 0,
    batteryLevel: 0
  }

  const _onSearchClicked = (values: any) => {
    props.onSearch(values)
  }

  return (
    <Accordion elevation={0}>
      <AccordionSummary
        classes={{
          root: classes.titleContainer,
          content: classes.contentSummaryContainer
        }}
        expandIcon={<ExpandMoreIcon style={{color: '#FFFFFF'}} />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography className={classes.heading}>{t('common.search')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.detailContainer}>
        <Form
          initialValues={initialValue}
          onSubmit={_onSearchClicked}
          mutators={{
            setTeams: (args, state, utils) => {
              console.log('[setTeams] args: ', args)
              utils.changeValue(state, 'teams', () => null)
            }
          }}
          render={(formProps: FormRenderProps<FormData>) => (
            <form onSubmit={formProps.handleSubmit}>
              <Grid container spacing={2}>
                {_isNotExcluded(EmployeeFilterField.FIRSTNAME) && (
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      label={t('employee.firstName')}
                      variant="outlined"
                      fullWidth
                      className={classes.inputField}
                      size="small"
                    />
                  </Grid>
                )}
                {_isNotExcluded(EmployeeFilterField.LASTNAME) && (
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      label={t('employee.lastName')}
                      variant="outlined"
                      className={classes.inputField}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                )}
                {_isNotExcluded(EmployeeFilterField.EMAIL) && (
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="email"
                      name="email"
                      label={t('employee.email')}
                      variant="outlined"
                      className={classes.inputField}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                )}
                {_isNotExcluded(EmployeeFilterField.STATUS) && (
                  <Grid item md={4} sm={6} xs={12}>
                    <Select
                      labelId="work-status-select"
                      id="work-status-select"
                      name="status"
                      // value={filter.status}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      className={classes.inputField}>
                      <MenuItem value={0}>--{t('employee.status')}--</MenuItem>
                      <MenuItem value={'NOT_WORKING'}>
                        {t('employee.statusText.notWorking')}
                      </MenuItem>
                      <MenuItem value={'WORKING'}>{t('employee.statusText.working')}</MenuItem>
                      <MenuItem value={'AT_CLIENT'}>{t('employee.statusText.atClient')}</MenuItem>
                      <MenuItem value={'ON_LEAVE'}>{t('employee.statusText.outOfOffice')}</MenuItem>
                    </Select>
                  </Grid>
                )}
                {_isNotExcluded(EmployeeFilterField.TEAM) && (
                  <Grid item md={4} sm={6} xs={12}>
                    <Autocomplete
                      id="teams"
                      key={isTeamCleared.toString()}
                      label={t('employee.teamName')}
                      name="teams"
                      options={props.teams}
                      classes={{root: classes.inputField}}
                      filterSelectedOptions
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => {
                        return {
                          teamId: option.teamId
                        }
                      }}
                      getOptionSelected={(option, value) => value.teamId === option.teamId}
                      size="small"
                      textFieldProps={{
                        variant: 'outlined',
                        size: 'small'
                      }}
                    />
                  </Grid>
                )}
                {_isNotExcluded(EmployeeFilterField.LASTTIMEONLINE) && (
                  <Grid item md={4} sm={6} xs={12}>
                    <Select
                      labelId="lastTimeOnline"
                      name="lastTimeOnline"
                      // value={filter.lastTimeOnline}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      className={classes.inputField}
                      // onChange={_handleSelectChange}>
                    >
                      <MenuItem value={0}>--{t('employee.lastOnline')}--</MenuItem>
                      <MenuItem value={14}>Laatste 14 dagen</MenuItem>
                      <MenuItem value={30}>Laatste 30 dagen</MenuItem>
                      <MenuItem value={60}>Laatste 60 dagen</MenuItem>
                      <MenuItem value={90}>Laatste 90 dagen</MenuItem>
                    </Select>
                  </Grid>
                )}
                {_isNotExcluded(EmployeeFilterField.BATTERY) && (
                  <Grid item xs={12}>
                    <Select
                      labelId="batteryLevel"
                      name="batteryLevel"
                      // value={filter.batteryLevel}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      className={classes.inputField}
                      // onChange={_handleSelectChange}>
                    >
                      <MenuItem value={0}>--{t('battery.status')}--</MenuItem>
                      <MenuItem value={batteryLevelEnum.UNKNOWN}>{t('battery.unknown')}</MenuItem>
                      <MenuItem value={batteryLevelEnum.LOW}>{t('battery.low')}</MenuItem>
                      <MenuItem value={batteryLevelEnum.MEDIUM}>{t('battery.medium')}</MenuItem>
                      <MenuItem value={batteryLevelEnum.HIGH}>{t('battery.high')}</MenuItem>
                    </Select>
                  </Grid>
                )}
                <Grid item sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="medium"
                    className={classes.btn}
                    // onClick={_onSearchClicked}>
                  >
                    {t('common.search')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.btn}
                    type="submit"
                    onClick={() => {
                      setTeamCleared(!isTeamCleared)
                      formProps.form.reset()
                    }}>
                    {t('common.reset')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default EmployeeFilter
