import React, {useState} from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Paper from '@material-ui/core/Paper'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import Colors from '../../commons/constants/colors'
import Sizes from '../../commons/constants/sizes'
import Constants from '../../commons/constants/constants'
import AddressTableRow from './AddressTableRow'

const useStyles = makeStyles({
  actionButton: {
    textAlign: 'right'
  },
  row: {
    color: '#848484'
  },
  primaryTitle: {
    backgroundColor: '#3f51b5',
    color: 'white',
    padding: '1rem 0.75rem',
    margin: 0
  },
  containedTitle: {
    backgroundColor: '#F5F6FA',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  legendContainer: {
    display: 'inline-block',
    padding: Sizes.largeMargin,
    marginTop: Sizes.defaultMargin,
    marginRight: Sizes.defaultMargin
  },
  paginationContainer: {
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: '0px',
    float: 'right'
  },
  paginationSelect: {
    backgroundColor: 'white'
  },
  paginationArrow: {
    color: Colors.primary
  },
  plainTitle: {
    backgroundColor: 'white',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  table: {
    backgroundColor: 'white'
  },
  tableHead: {
    backgroundColor: Colors.lightGray,
    borderTop: '1px solid',
    borderTopColor: Colors.gray,
    borderBottom: '2px solid',
    borderBottomColor: Colors.gray
  },
  tableHeadCell: {
    color: Colors.primary,
    fontWeight: 'bold'
  },
  thActions: {
    color: Colors.primary,
    fontWeight: 'bold',
    width: '130px'
  },
  thAdditionalInfo: {
    color: Colors.primary,
    fontWeight: 'bold',
    width: '200px'
  },
  tSortIconActive: {
    opacity: 1,
    color: Colors.primaryDarkest
  },
  tSortIconInactive: {
    opacity: 0.2,
    color: Colors.primaryDarkest
  },
  tCellIcon: {
    width: '35px',
    textAlign: 'center',
    paddingLeft: Sizes.defaultPadding,
    paddingRight: Sizes.defaultPadding
  },
  wrapIcon: {
    verticalAlign: 'text-bottom',
    alignItems: 'center'
  }
})
const AddressTable = (props) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const [pagination, setPagination] = useState(Constants.defaultAddressPagination)
  const [orderBy, setOrderBy] = useState('firstName')
  const [order, setOrder] = useState('asc')

  let headerClass
  let size = 'small'

  const _onPageChanged = (event, page) => {
    const updatedPagination = {
      ...pagination,
      page: page
    }
    setPagination(updatedPagination)
    props.onChangePagination(updatedPagination)
  }

  const _onRowPerPageChanged = (event) => {
    const updatedPagination = {
      ...pagination,
      size: event.target.value
    }
    setPagination(updatedPagination)
    props.onChangePagination(updatedPagination)
  }

  const _createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder)
    setOrderBy(property)
    const updatedPagination = {
      ...pagination,
      sort: `${property},${newOrder}`
    }
    props.onChangePagination(updatedPagination)
  }

  const headCells = [
    {
      id: 'address',
      disablePadding: true,
      label: t('location.address'),
      classes: classes.tableHeadCell
    },
    {
      id: 'postalCode',
      disablePadding: false,
      label: t('location.postalCode'),
      classes: classes.tableHeadCell
    },
    {id: 'city', disablePadding: false, label: t('location.city'), classes: classes.tableHeadCell},
    {
      id: 'additionalInfo',
      disablePadding: false,
      label: t('location.additionalInfo'),
      classes: classes.thAdditionalInfo
    }
  ]

  return (
    <TableContainer>
      {props.title && <h4 className={headerClass}>{props.title}</h4>}
      <Table className={classes.table} size={size} fixedHeader={true}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  headCell.id === 'additionalInfo'
                    ? classes.thAdditionalInfo
                    : classes.tableHeadCell
                }>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={_createSortHandler(headCell.id)}
                  IconComponent={ArrowDropDownIcon}
                  classes={{
                    icon:
                      orderBy === headCell.id ? classes.tSortIconActive : classes.tSortIconInactive
                  }}>
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell key="actions" className={classes.thActions}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.rows.length > 0 ? (
            props.rows.map((row) => (
              <AddressTableRow
                address={row}
                onEditAddress={props.onEditAddress}
                onDeleteAddress={props.onDeleteAddress}
                onRestoreAddress={props.onRestoreAddress}
              />
            ))
          ) : (
            <TableRow></TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow></TableRow>
        </TableFooter>
      </Table>
      <TablePagination
        component="div"
        className={classes.paginationContainer}
        rowsPerPageOptions={[10, 25, 50, 100]}
        labelRowsPerPage={''}
        labelDisplayedRows={({from, to, count}) =>
          t('location.numberOfRecords', {from: from, to: to, count: count})
        }
        count={props.total}
        rowsPerPage={pagination.size}
        page={pagination.page}
        SelectProps={{
          inputProps: {'aria-label': 'rows per page'}
        }}
        onChangePage={_onPageChanged}
        onChangeRowsPerPage={_onRowPerPageChanged}
        nextIconButtonProps={{color: 'primary'}}
        backIconButtonProps={{color: 'primary'}}
        classes={{
          select: classes.paginationSelect,
          actions: classes.paginationArrow
        }}
      />
    </TableContainer>
  )
}

AddressTable.propTypes = {
  rows: PropTypes.array,
  total: PropTypes.number.isRequired,
  onEditAddress: PropTypes.func.isRequired,
  onDeleteAddress: PropTypes.func.isRequired,
  onChangePagination: PropTypes.func.isRequired,
  onRestoreAddress: PropTypes.func
}

export default AddressTable
