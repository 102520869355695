const AUTH_REQUESTED = 'safe/auth/login_requested'
const AUTH_SUCCEEDED = 'safe/auth/login_succeeded'
const AUTH_FAILED = 'safe/auth/login_failed'
const LOGOUT_REQUESTED = 'safe/auth/logout_requested'
const LOGOUT_SUCCEEDED = 'safe/auth/logout_succeeded'
const LOGOUT_FAILED = 'safe/auth/logout_failed'
const CHANGE_PASSWORD_REQUESTED = 'safe/auth/change_password_requested'
const CHANGE_PASSWORD_SUCCEEDED = 'safe/auth/change_password_succeeded'
const CHANGE_PASSWORD_FAILED = 'safe/auth/change_password_failed'
const VALIDATE_RESET_TOKEN_REQUESTED = 'safe/auth/validate_token_requested'
const VALIDATE_RESET_TOKEN_SUCCEEDED = 'safe/auth/validate_token_succeeded'
const VALIDATE_RESET_TOKEN_FAILED = 'safe/auth/validate_token_failed'
const RESET_TOKEN_REQUESTED = 'safe/auth/token_requested'
const RESET_TOKEN_SUCCEEDED = 'safe/auth/token_succeeded'
const RESET_TOKEN_FAILED = 'safe/auth/token_failed'
const RESET_PASSWORD_BY_TOKEN_REQUESTED = 'safe/auth/reset_password_by_token_requested'
const RESET_PASSWORD_BY_TOKEN_SUCCEEDED = 'safe/auth/reset_password_by_token_succeeded'
const RESET_PASSWORD_BY_TOKEN_FAILED = 'safe/auth/reset_password_by_token_failed'
const GET_LOGGED_IN_USER_REQUESTED = 'safe/auth/get_logged_in_user_requested'
const GET_LOGGED_IN_USER_SUCCEEDED = 'safe/auth/get_logged_in_user_succeeded'
const GET_LOGGED_IN_USER_FAILED = 'safe/auth/get_logged_in_user_failed'
const CLEAR_MSG = 'safe/auth/clear_msg'
const CLEAR_DIALOG = 'safe/auth/clear_dialog'
export {
  AUTH_REQUESTED,
  AUTH_SUCCEEDED,
  AUTH_FAILED,
  LOGOUT_REQUESTED,
  LOGOUT_SUCCEEDED,
  LOGOUT_FAILED,
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCEEDED,
  CHANGE_PASSWORD_FAILED,
  VALIDATE_RESET_TOKEN_REQUESTED,
  VALIDATE_RESET_TOKEN_SUCCEEDED,
  VALIDATE_RESET_TOKEN_FAILED,
  RESET_TOKEN_REQUESTED,
  RESET_TOKEN_SUCCEEDED,
  RESET_TOKEN_FAILED,
  RESET_PASSWORD_BY_TOKEN_REQUESTED,
  RESET_PASSWORD_BY_TOKEN_SUCCEEDED,
  RESET_PASSWORD_BY_TOKEN_FAILED,
  GET_LOGGED_IN_USER_REQUESTED,
  GET_LOGGED_IN_USER_SUCCEEDED,
  GET_LOGGED_IN_USER_FAILED,
  CLEAR_MSG,
  CLEAR_DIALOG
}
