import {
  EMPLOYEE_ADD,
  EMPLOYEE_REMOVE,
  EMPLOYEE_RESET_PASSWORD_BY_ADMIN_REQUESTED,
  EMPLOYEE_RESET_PASSWORD_BY_ADMIN_SUCCEEDED,
  EMPLOYEE_RESET_PASSWORD_LOGIN,
  EMPLOYEE_CLEAR_RESET_PASSWORD_LOGIN,
  EMPLOYEES_FETCH_SUCCEEDED,
  EMPLOYEES_FETCH_REQUESTED,
  EMPLOYEES_FETCH_FAILED,
  EMPLOYEE_DETAIL_ADD,
  EMPLOYEE_DETAIL_REMOVE,
  EMPLOYEE_DETAIL_FETCH_FAILED,
  EMPLOYEE_DETAIL_FETCH_SUCCEEDED,
  EMPLOYEE_DETAIL_FETCH_REQUESTED,
  EMPLOYEE_DETAIL_DELETE_REQUESTED,
  EMPLOYEE_DETAIL_SAVE_REQUESTED,
  EMPLOYEE_TEAMS_FETCH_SUCCEEDED,
  EMPLOYEE_TEAMS_FETCH_REQUESTED,
  EMPLOYEE_UPLOAD_REQUESTED,
  EMPLOYEE_UPLOAD_SUCCEEDED,
  EMPLOYEE_UPLOAD_FAILED,
  EMPLOYEE_DETAIL_UPDATE_REQUESTED,
  EMPLOYEE_DETAIL_UPDATE_SUCCEEDED,
  EMPLOYEE_DETAIL_UPDATE_FAILED,
  EMPLOYEE_DETAIL_SAVE_FAILED,
  EMPLOYEE_DETAIL_SAVE_SUCCEEDED,
  ERROR_MSG_CLEAR,
  SUCCESS_MSG_CLEAR,
  EMPLOYEE_UPLOAD_EMPTY,
  EMPLOYEE_RESET_PASSWORD_BY_ADMIN_FAILED,
  EMPLOYEE_DETAIL_DELETE_SUCCEEDED,
  EMPLOYEE_DETAIL_DELETE_FAILED,
  EMPLOYEE_TEAMS_FETCH_FAILED,
  EMPLOYEE_BUTTON_FETCH_REQUESTED,
  EMPLOYEE_BUTTON_FETCH_SUCCEEDED,
  EMPLOYEE_BUTTON_FETCH_FAILED
} from './types'

const addEmployee = (employeeJson) => ({
  type: EMPLOYEE_ADD,
  payload: employeeJson
})

const removeEmployee = () => ({
  type: EMPLOYEE_REMOVE
})

const resetPasswordEmployeeByAdminRequested = (employeeId, adminPassword) => ({
  type: EMPLOYEE_RESET_PASSWORD_BY_ADMIN_REQUESTED,
  payload: {
    employeeId: employeeId,
    adminPassword: adminPassword
  }
})

const resetPasswordEmployeeByAdminSucceeded = () => ({
  type: EMPLOYEE_RESET_PASSWORD_BY_ADMIN_SUCCEEDED
})

const resetPasswordEmployeeByAdminFailed = () => ({
  type: EMPLOYEE_RESET_PASSWORD_BY_ADMIN_FAILED
})

const resetPasswordLoginEmployee = (email, password) => ({
  type: EMPLOYEE_RESET_PASSWORD_LOGIN,
  payload: {
    email: email,
    password: password
  }
})

const clearResetPasswordEmployee = () => ({
  type: EMPLOYEE_CLEAR_RESET_PASSWORD_LOGIN
})

const fetchEmployeeRequested = (filter, pagination, isDAR) => ({
  type: EMPLOYEES_FETCH_REQUESTED,
  payload: {filter, pagination, isDAR}
})

const fetchEmployeeSuccess = (employees) => ({
  type: EMPLOYEES_FETCH_SUCCEEDED,
  payload: employees
})

const fetchEmployeeFailed = () => ({
  type: EMPLOYEES_FETCH_FAILED
})

const addEmployeeDetail = (employeeJson) => ({
  type: EMPLOYEE_DETAIL_ADD,
  payload: employeeJson
})

const removeEmployeeDetail = () => ({
  type: EMPLOYEE_DETAIL_REMOVE
})

const fetchEmployeeDetailRequested = (employeeId) => ({
  type: EMPLOYEE_DETAIL_FETCH_REQUESTED,
  payload: employeeId
})

const fetchEmployeeDetailSuccess = (employee) => ({
  type: EMPLOYEE_DETAIL_FETCH_SUCCEEDED,
  payload: employee
})

const fetchEmployeeDetailFailed = (msg) => ({
  type: EMPLOYEE_DETAIL_FETCH_FAILED,
  payload: {msg: msg}
})

const updateEmployeeDetailRequested = (employee, history) => ({
  type: EMPLOYEE_DETAIL_UPDATE_REQUESTED,
  payload: {detailEmployee: employee, history: history}
})

const updateEmployeeDetailSucceeded = (employee, history) => ({
  type: EMPLOYEE_DETAIL_UPDATE_SUCCEEDED,
  payload: {detailEmployee: employee, history: history}
})

const updateEmployeeDetailFailed = (msg) => ({
  type: EMPLOYEE_DETAIL_UPDATE_FAILED,
  payload: {msg}
})

const saveEmployeeDetailRequested = (employee, history) => ({
  type: EMPLOYEE_DETAIL_SAVE_REQUESTED,
  payload: {employee, history}
})

const saveEmployeeDetailSucceeded = (employee, history) => ({
  type: EMPLOYEE_DETAIL_SAVE_SUCCEEDED,
  payload: {employee, history}
})

const saveEmployeeDetailFailed = (msg) => ({
  type: EMPLOYEE_DETAIL_SAVE_FAILED,
  payload: {msg}
})

const fetchTeamsRequested = () => ({
  type: EMPLOYEE_TEAMS_FETCH_REQUESTED
})
const fetchTeamsSuccess = (employee) => ({
  type: EMPLOYEE_TEAMS_FETCH_SUCCEEDED,
  payload: employee
})
const fetchTeamsFailed = () => ({
  type: EMPLOYEE_TEAMS_FETCH_FAILED
})
const postEmployeeRequested = (employee) => ({
  type: EMPLOYEE_DETAIL_SAVE_REQUESTED,
  payload: employee
})

const deleteEmployeeRequested = (employeeId, history) => ({
  type: EMPLOYEE_DETAIL_DELETE_REQUESTED,
  payload: {employeeId: employeeId, history: history}
})

const deleteEmployeeSucceed = (isDAR) => ({
  type: EMPLOYEE_DETAIL_DELETE_SUCCEEDED,
  payload: {isDAR: isDAR}
})

const deleteEmployeeFailed = () => ({
  type: EMPLOYEE_DETAIL_DELETE_FAILED
})

const uploadEmployeeRequested = (file, history) => ({
  type: EMPLOYEE_UPLOAD_REQUESTED,
  payload: {file: file, history: history}
})

const uploadEmployeeSucceeded = (msg, history) => ({
  type: EMPLOYEE_UPLOAD_SUCCEEDED,
  payload: {msg: msg, history: history}
})

const uploadEmployeeFailed = (msg) => ({
  type: EMPLOYEE_UPLOAD_FAILED,
  payload: {msg: msg}
})

const fetchEmployeeButtonRequested = (employeeId) => ({
  type: EMPLOYEE_BUTTON_FETCH_REQUESTED,
  payload: {employeeId}
})

const fetchEmployeeButtonSuccess = (employeeId, button) => ({
  type: EMPLOYEE_BUTTON_FETCH_SUCCEEDED,
  payload: {employeeId, button}
})

const fetchEmployeeButtonFailed = (msg) => ({
  type: EMPLOYEE_BUTTON_FETCH_FAILED,
  payload: {msg: msg}
})

const clearErrorMsg = () => ({
  type: ERROR_MSG_CLEAR
})

const clearSuccessMsg = () => ({
  type: SUCCESS_MSG_CLEAR
})

const errorFileEmpty = () => ({
  type: EMPLOYEE_UPLOAD_EMPTY
})

export {
  addEmployee,
  removeEmployee,
  resetPasswordEmployeeByAdminRequested,
  resetPasswordEmployeeByAdminSucceeded,
  resetPasswordEmployeeByAdminFailed,
  resetPasswordLoginEmployee,
  clearResetPasswordEmployee,
  fetchEmployeeRequested,
  fetchEmployeeSuccess,
  fetchEmployeeFailed,
  addEmployeeDetail,
  fetchTeamsRequested,
  fetchTeamsSuccess,
  fetchTeamsFailed,
  fetchEmployeeDetailRequested,
  fetchEmployeeDetailSuccess,
  fetchEmployeeDetailFailed,
  removeEmployeeDetail,
  postEmployeeRequested,
  deleteEmployeeRequested,
  deleteEmployeeSucceed,
  deleteEmployeeFailed,
  uploadEmployeeRequested,
  uploadEmployeeSucceeded,
  uploadEmployeeFailed,
  updateEmployeeDetailRequested,
  updateEmployeeDetailFailed,
  updateEmployeeDetailSucceeded,
  saveEmployeeDetailRequested,
  saveEmployeeDetailFailed,
  saveEmployeeDetailSucceeded,
  fetchEmployeeButtonRequested,
  fetchEmployeeButtonSuccess,
  fetchEmployeeButtonFailed,
  clearErrorMsg,
  clearSuccessMsg,
  errorFileEmpty
}
