import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import RestRequester from '../../api/RestRequester'
import * as types from './types'
import * as actions from './actions'
import Constants from '../../commons/constants/constants'
import urls from '../../commons/constants/urls'

function* fetchUser(action) {
  try {
    const employee = yield call(() => RestRequester.getEmployee(action.payload))
    yield put(actions.fetchEmployeeDetailSuccess(employee))
  } catch (error) {
    yield put(actions.fetchEmployeeDetailFailed(`server_response:${error.response.data}`))
  }
}

function* fetchUserList(action) {
  try {
    const response = yield call(() =>
      RestRequester.getEmployeeList(
        action.payload.pagination,
        action.payload.filter,
        action.payload.isDAR
      )
    )
    yield put(actions.fetchEmployeeSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(actions.fetchEmployeeFailed())
  }
}

function* updateUser(action) {
  try {
    const employee = yield call(() => RestRequester.updateEmployee(action.payload.detailEmployee))
    const history = action.payload.history
    yield put(actions.updateEmployeeDetailSucceeded(employee, history))
  } catch (error) {
    yield put(actions.updateEmployeeDetailFailed(error.response.data))
  }
}

function* updateUserSucceed(action) {
  yield call(() => action.payload.history.goBack())
}

function* saveUser(action) {
  try {
    const employee = yield call(() => RestRequester.saveEmployee(action.payload.employee))
    const history = action.payload.history

    yield put(actions.saveEmployeeDetailSucceeded(employee, history))
  } catch (error) {
    console.log(error.response)
    yield put(actions.saveEmployeeDetailFailed(error.response.data))
  }
}

function* saveUserSucceed(action) {
  yield call(() => action.payload.history.goBack())
}

function* fetchTeams(action) {
  try {
    const response = yield call(() => RestRequester.getTeamList())
    const teams = response.data
    yield put(actions.fetchTeamsSuccess(teams))
  } catch {
    yield put(actions.fetchTeamsFailed())
  }
}

function* deleteEmployee(action) {
  try {
    yield call(() => RestRequester.deleteEmployee(action.payload.employeeId))
    const history = action.payload.history
    const {pathname} = history.location
    const topPath = `/${pathname.split('/')[1]}`
    const isDAR = topPath === urls.alarmReceiverPage
    yield put(actions.deleteEmployeeSucceed(isDAR))
  } catch (error) {
    console.log(error)
    yield put(actions.deleteEmployeeFailed())
  }
}

function* deleteEmployeeSucceed(action) {
  yield put(
    actions.fetchEmployeeRequested(
      Constants.defaultEmployeeFilter,
      Constants.defaultEmployeePagination,
      action.payload.isDAR
    )
  )
}

function* resetPasswordByAdmin(action) {
  try {
    yield call(() =>
      RestRequester.resetPassword(action.payload.employeeId, action.payload.adminPassword)
    )
    yield put(actions.resetPasswordEmployeeByAdminSucceeded())
  } catch (error) {
    console.error(error)
    yield put(actions.resetPasswordEmployeeByAdminFailed())
  }
}

function* uploadEmployeeRequested(action) {
  try {
    const response = yield call(() => RestRequester.uploadEmployeeList(action.payload.file))
    yield put(actions.uploadEmployeeSucceeded(response.data, action.payload.history))
  } catch (error) {
    console.error(error)
    yield put(actions.uploadEmployeeFailed(error.response.data))
  }
}

function* uploadEmployeeSuccess(action) {
  const history = action.payload.history
  if (history.location.pathname !== '/medewerker') {
    yield call(() => history.goBack())
  }
}

function* getEmployeeButton(action) {
  try {
    const response = yield call(() => RestRequester.getBatteryOfEmployee(action.payload.employeeId))
    yield put(actions.fetchEmployeeButtonSuccess(action.payload.employeeId, response.data))
  } catch (error) {
    console.error(error)
    yield put(actions.fetchEmployeeButtonFailed(action.payload.employeeId, error.response.data))
  }
}

function* saga() {
  yield all([
    takeEvery(types.EMPLOYEES_FETCH_REQUESTED, fetchUserList),
    takeEvery(types.EMPLOYEE_DETAIL_UPDATE_REQUESTED, updateUser),
    takeEvery(types.EMPLOYEE_DETAIL_UPDATE_SUCCEEDED, updateUserSucceed),
    takeEvery(types.EMPLOYEE_DETAIL_SAVE_REQUESTED, saveUser),
    takeEvery(types.EMPLOYEE_DETAIL_SAVE_SUCCEEDED, saveUserSucceed),
    takeEvery(types.EMPLOYEE_DETAIL_DELETE_REQUESTED, deleteEmployee),
    takeEvery(types.EMPLOYEE_DETAIL_DELETE_SUCCEEDED, deleteEmployeeSucceed),
    takeEvery(types.EMPLOYEE_DETAIL_FETCH_REQUESTED, fetchUser),
    takeEvery(types.EMPLOYEE_TEAMS_FETCH_REQUESTED, fetchTeams),
    takeEvery(types.EMPLOYEE_RESET_PASSWORD_BY_ADMIN_REQUESTED, resetPasswordByAdmin),
    takeLatest(types.EMPLOYEE_UPLOAD_REQUESTED, uploadEmployeeRequested),
    takeLatest(types.EMPLOYEE_UPLOAD_SUCCEEDED, uploadEmployeeSuccess),
    takeLatest(types.EMPLOYEE_BUTTON_FETCH_REQUESTED, getEmployeeButton)
  ])
}

export default saga
