import React, {useEffect, useState} from 'react'
import {Form, Field} from 'react-final-form'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {useHistory} from 'react-router-dom'

import LoadingIndicator from '../../components/LoadingIndicator'
import TextFieldAdapter from '../../components/forms/TextFieldAdapter'
import DatePickerAdapter from '../../components/forms/DatePickerAdapter'
import Sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import {required, emailValidation} from '../../commons/validator'
import RestRequester from '../../api/RestRequester'

// export interface Props {}

const useStyles = makeStyles({
  btn: {
    marginRight: Sizes.defaultMargin
  },
  copyright: {
    marginTop: '3rem',
    color: Colors.text
  },
  download: {
    marginTop: '2rem',
    color: Colors.text,
    fontSize: '0.8em'
  },
  form: {
    width: '100%'
  },
  formContainer: {
    textAlign: 'center',
    height: '100vh'
  },
  heading: {
    backgroundColor: Colors.primary,
    color: 'white',
    fontWeight: 'bolder',
    padding: '1rem',
    marginBottom: Sizes.defaultPadding
  },
  inputField: {
    color: Colors.textLight
  },
  section: {
    marginBottom: Sizes.defaultMargin
  }
})

const ReleaseDetailScreen = (props) => {
  const classes = useStyles()
  const {t, i18n} = useTranslation()
  const [version, setVersion] = useState()
  const [note, setNote] = useState('')
  const [date, setDate] = useState(new Date())
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const history = useHistory()
  const navState = props.location.state

  useEffect(() => {
    if (navState && navState.releaseId) {
      setLoading(true)
      RestRequester.getReleaseNote(navState.releaseId)
        .then((response) => {
          setLoading(false)
          const payload = response.data
          if (payload.success) {
            setDate(payload.data.releaseDate)
            setNote(payload.data.releaseNote)
            setVersion(payload.data.version)
          }
        })
        .catch((error) => {
          setLoading(false)
          console.error(error.response)
        })
    }
  }, [])

  const _navigateBack = () => {
    history.goBack()
  }

  const _onSubmitNote = (values) => {
    const payload = {
      version: values.version,
      releaseDate: values.releaseDate,
      releaseNote: note
    }
    setLoading(true)
    if (navState && navState.releaseId) {
      RestRequester.updateReleaseNote(payload, navState.releaseId)
        .then((response) => {
          setLoading(false)
          const data = response.data
          if (data.success) {
            history.goBack()
          } else {
            setError(data.message)
          }
        })
        .catch((error) => {
          setLoading(false)
          console.error(error.response)
        })
    } else {
      RestRequester.addReleaseNote(payload)
        .then((response) => {
          setLoading(false)
          const data = response.data
          if (data.success) {
            history.goBack()
          } else {
            setError(data.message)
          }
        })
        .catch((error) => {
          setLoading(false)
          console.error(error.response)
        })
    }
  }

  return (
    <Container>
      <Form
        // reducer={props.authReducer}
        onSubmit={_onSubmitNote}
        render={(props) => (
          <Grid item md={9} className={classes.section}>
            <Typography className={classes.heading}>{t('release.addNew')}</Typography>

            {error && (
              <div className={classes.section}>
                <Alert severity="error" onClose={() => setError()}>
                  {t(error)}
                </Alert>
              </div>
            )}

            <form onSubmit={props.handleSubmit}>
              <FormControl className={classes.form}>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item md={6}>
                    <Field
                      name="version"
                      id="version"
                      label={t('release.version')}
                      validate={(value) => required(value, i18n)}
                      component={TextFieldAdapter}
                      className={classes.inputField}
                      InputProps={{style: {backgroundColor: 'white'}}}
                      variant="outlined"
                      defaultValue={version}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="releaseDate"
                      id="releaseDate"
                      label={t('release.releaseDate')}
                      validate={(value) => required(value, i18n)}
                      component={DatePickerAdapter}
                      className={classes.inputField}
                      style={{
                        backgroundColor: 'white',
                        width: '100%',
                        marginTop: 0,
                        marginBottom: 0,
                        color: Colors.text
                      }}
                      variant="outlined"
                      initialValue={date}
                      inputVariant={'outlined'}
                      inputFormat="dd MMMM yyyy"
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item md={12}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={note}
                      onChange={(event, editor) => {
                        setNote(editor.getData())
                      }}
                      config={{
                        removePlugins: ['MediaEmbed', 'Blockquote', 'Image', 'ImageToolbar'],
                        mediaEmbed: {},
                        toolbar: [
                          'heading',
                          'bold',
                          'italic',
                          'link',
                          'bulletedList',
                          'numberedList',
                          '|',
                          'undo',
                          'redo'
                        ]
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Button
                      disabled={props.submitting}
                      variant="contained"
                      color="primary"
                      size="medium"
                      type="submit"
                      className={classes.btn}>
                      {t('common.save')}
                    </Button>
                    <Button
                      disabled={props.submitting}
                      variant="outlined"
                      color="primary"
                      size="medium"
                      className={classes.btn}
                      onClick={_navigateBack}>
                      {t('common.cancel')}
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </form>
          </Grid>
        )}
      />
      <LoadingIndicator open={isLoading} />
    </Container>
  )
}

export default ReleaseDetailScreen
