import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import PropTypes from 'prop-types'
import Colors from '../commons/constants/colors'
import sizes from '../commons/constants/sizes'

const useStyles = makeStyles({
  avatar: {
    backgroundColor: Colors.primary
  },
  root: {
    width: 'fit-content'
  },
  closeButton: {
    padding: 0
  },
  pos: {
    marginBottom: 12
  },
  title: {
    marginRight: sizes.defaultMargin
  }
})

const imageOffset = {
  left: 12,
  top: 24
}

const MapOverlay = (props) => {
  const [hover, setHover] = useState(false)
  const classes = useStyles()
  let eventParameters = (event) => ({event, anchor: props.anchor, payload: props.payload})

  const _handleMouseOver = (event) => {
    props.onMouseOver && props.onMouseOver(eventParameters(event))
    setHover(true)
  }

  const _handleMouseOut = (event) => {
    props.onMouseOut && props.onMouseOut(eventParameters(event))
    setHover(false)
  }

  const _handleClick = (event) => {
    props.onClick && props.onClick(eventParameters(event))
  }

  const _renderAlarmType = (type) => {
    let alarmType
    switch (type) {
      case 'NEED_SUPPORT':
        alarmType = 'Alarmtype: Help'
        break
      case 'CALL_112':
        alarmType = 'Alarmtype: Bel 112'
        break
      default:
        alarmType = 'Unknown alarm'
        break
    }
    return alarmType
  }

  const style = {
    position: 'absolute',
    transform: `translate(${props.left}px, ${props.top}px)`,
    cursor: props.onClick ? 'pointer' : 'default'
  }
  return (
    <Card className={classes.root} variant="outlined" style={style}>
      <CardHeader
        title={_renderAlarmType(props.alarmType)}
        avatar={
          <Avatar className={classes.avatar}>
            <NotificationImportantIcon />
          </Avatar>
        }
        action={
          <IconButton aria-label="settings" color={Colors.lightGray} onClick={_handleClick}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Typography variant="body2" component="p">
          {props.address}
        </Typography>
        <Typography variant="body2" component="p">
          {`${props.postalCode || ''} ${props.city || ''}`}
        </Typography>
      </CardContent>
    </Card>
  )
}

MapOverlay.propTypes = {
  address: PropTypes.string.isRequired,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  alarmType: PropTypes.string.isRequired
}
export default MapOverlay
