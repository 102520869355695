import * as types from './types'

const INITIAL_STATE = {
  activeEmployee: {},
  detailEmployee: {},
  listEmployee: [],
  listTeam: [],
  detailErrorMsg: '',
  detailSuccessMsg: '',
  isBatteryLoading: [],
  button: undefined,
  batteryLevel: [],
  isLoading: false,
  totalEmployee: 0
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.EMPLOYEE_ADD:
      let {activeEmployee} = state
      activeEmployee = action.payload
      const newState = {activeEmployee}
      return newState
    case types.EMPLOYEE_REMOVE:
      activeEmployee = {}
      const removedState = {activeEmployee}
      return removedState
    case types.EMPLOYEE_RESET_PASSWORD_BY_ADMIN_REQUESTED:
      return {...state, isLoading: true}
    case types.EMPLOYEE_RESET_PASSWORD_BY_ADMIN_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        detailSuccessMsg: 'Het wachtwoord is succesvol aangepast!'
      }
    case types.EMPLOYEE_RESET_PASSWORD_BY_ADMIN_FAILED:
      return {...state, isLoading: false, detailErrorMsg: 'employee.msg.wrongPassword'}
    case types.EMPLOYEE_RESET_PASSWORD_LOGIN:
      return {
        ...state,
        resetData: {
          ...state.resetData,
          email: action.payload.email,
          password: action.payload.password
        }
      }
    case types.EMPLOYEE_CLEAR_RESET_PASSWORD_LOGIN:
      return {
        ...state,
        resetData: {}
      }
    case types.EMPLOYEES_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case types.EMPLOYEES_FETCH_SUCCEEDED:
      return {
        ...state,
        listEmployee: action.payload.data,
        totalEmployee: action.payload.recordsFiltered,
        isLoading: false
      }
    case types.EMPLOYEES_FETCH_FAILED:
      return {
        ...state,
        listEmployee: [],
        totalEmployee: 0,
        isLoading: false
      }
    case types.EMPLOYEE_DETAIL_ADD:
      return {
        ...state,
        detailEmployee: action.payload
      }
    case types.EMPLOYEE_DETAIL_REMOVE:
      return {
        ...state,
        detailEmployee: {},
        detailErrorMsg: ''
      }
    case types.EMPLOYEE_DETAIL_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case types.EMPLOYEE_DETAIL_FETCH_SUCCEEDED:
      return {
        ...state,
        detailEmployee: action.payload.data,
        isLoading: false
      }
    case types.EMPLOYEE_DETAIL_FETCH_FAILED:
      return {
        ...state,
        detailEmployee: {},
        isLoading: false,
        detailErrorMsg: action.payload.msg
      }
    case types.EMPLOYEE_TEAMS_FETCH_SUCCEEDED:
      return {
        ...state,
        listTeam: action.payload
      }
    case types.EMPLOYEE_DETAIL_UPDATE_SUCCEEDED:
      return {
        ...state,
        detailEmployee: {}
      }
    case types.EMPLOYEE_DETAIL_UPDATE_FAILED:
      return {
        ...state,
        detailErrorMsg: `server_response:${action.payload.msg}`
      }
    case types.EMPLOYEE_DETAIL_SAVE_SUCCEEDED:
      return {
        ...state,
        detailEmployee: {}
      }
    case types.EMPLOYEE_DETAIL_SAVE_FAILED:
      return {
        ...state,
        detailErrorMsg: `server_response:${action.payload.msg}`
      }
    case types.EMPLOYEE_DETAIL_DELETE_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case types.EMPLOYEE_DETAIL_DELETE_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      }
    case types.EMPLOYEE_DETAIL_DELETE_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case types.EMPLOYEE_UPLOAD_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case types.EMPLOYEE_UPLOAD_FAILED:
      return {
        ...state,
        isLoading: false,
        detailErrorMsg: action.payload.msg
      }
    case types.EMPLOYEE_UPLOAD_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        detailSuccessMsg: action.payload.msg
      }
    case types.EMPLOYEE_BUTTON_FETCH_REQUESTED: {
      const employeeId = action.payload.employeeId.toString()
      const loadBatteryArray = {...state.isBatteryLoading}
      loadBatteryArray[employeeId] = true
      return {
        ...state,
        isBatteryLoading: loadBatteryArray
      }
    }
    case types.EMPLOYEE_BUTTON_FETCH_SUCCEEDED: {
      const employeeId = action.payload.employeeId
      const newButton = []
      const level = action.payload.button.batteryLevelEnum
      console.log('bat level', level)
      newButton[employeeId] = action.payload.button
      const loadBatteryArray = {...state.isBatteryLoading}
      loadBatteryArray[employeeId] = false
      const levelArray = {...state.batteryLevel}
      levelArray[employeeId] = level || 'UNKNOWN'
      return {
        ...state,
        isBatteryLoading: loadBatteryArray,
        batteryLevel: levelArray,
        button: {...state.button, ...newButton}
      }
    }

    case types.EMPLOYEE_BUTTON_FETCH_FAILED: {
      const employeeId = action.payload.employeeId
      const loadBatteryArray = {...state.isBatteryLoading}
      loadBatteryArray[employeeId] = false
      return {
        ...state,
        isBatteryLoading: loadBatteryArray,
        button: undefined
      }
    }
    case types.ERROR_MSG_CLEAR:
      return {
        ...state,
        detailErrorMsg: ''
      }
    case types.SUCCESS_MSG_CLEAR:
      return {
        ...state,
        detailSuccessMsg: ''
      }
    case types.EMPLOYEE_UPLOAD_EMPTY:
      return {
        ...state,
        detailErrorMsg: 'common.error.fileEmpty'
      }
    default:
      return state
  }
}

export default reducer
