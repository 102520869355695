import React, {useEffect, useState} from 'react'
import Map from 'pigeon-maps'
import {makeStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import AddBoxIcon from '@material-ui/icons/AddBox'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
// import Marker from 'pigeon-marker'
import Marker from '../../components/MapMarker'
import Overlay from '../../components/MapOverlay'
import LoadingIndicator from '../../components/LoadingIndicator'
import Sizes from '../../commons/constants/sizes'
import * as actions from './actions'
// import Overlay from 'pigeon-overlay'

const MAX_ZOOM = 18
const MIN_ZOOM = 1
const providers = {
  osm: (x, y, z) => {
    const s = String.fromCharCode(97 + ((x + y + z) % 3))
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`
  },
  stamen: (x, y, z) => {
    const s = String.fromCharCode(97 + ((x + y + z) % 3))
    return `https://stamen-tiles-${s}.a.ssl.fastly.net/terrain/${z}/${x}/${y}.png`
  },
  wikimedia: (x, y, z) => `https://maps.wikimedia.org/osm-intl/${z}/${x}/${y}.png`
}

const useStyles = makeStyles({
  root: {
    minHeight: '400px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  iconButton: {
    padding: 0,
    color: '#000000'
  },
  zoomContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: Sizes.largeMargin,
    right: Sizes.defaultMargin
  }
})

const markers = {
  rithm: [[51.9165, 4.4817], 17]
}

const AppointmentScreen = (props) => {
  const [center, setCenter] = useState([51.928, 4.4204])
  const [zoom, setZoom] = useState(12)
  const [zoomSnap, setZoomSnap] = useState(true)
  const [mouseEvents, setMouseEvents] = useState(true)
  const [touchEvents, setTouchEvents] = useState(true)
  const [minZoom, setMinZoom] = useState(MIN_ZOOM)
  const [maxZoom, setMaxZoom] = useState(MAX_ZOOM)
  const [dialogShown, setDialogShown] = useState(false)
  const [provider, setProvider] = useState('wikimedia')
  const [card, setCard] = useState({
    anchor: [51.928, 4.4204],
    address: '',
    alarmType: '',
    city: '',
    postalCode: ''
  })
  const classes = useStyles()

  useEffect(() => {
    document.title = 'SAFE | Afspraak'

    props.dispatch(actions.requestAppointmentsWithAlarm())

    return () => {
      props.dispatch(actions.clearAppointmentWithAlarm())
    }
  }, [])

  const handleBoundsChange = ({center, zoom, bounds, initial}) => {
    // this.setState({center, zoom})
    setCenter(center)
    setZoom(zoom)
  }

  const handleMarkerClick = ({event, payload, anchor}) => {
    setCenter(anchor)
    if (payload) {
      setCard({
        anchor: anchor,
        address: payload.address,
        alarmType: payload.type,
        city: payload.city,
        postalCode: payload.postalCode
      })
      setDialogShown(true)
    }
  }

  const StamenAttribution = () => (
    <span className="map-attribution">
      Map tiles by <a href="http://stamen.com">Stamen Design</a>, under{' '}
      <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by{' '}
      <a href="http://openstreetmap.org">OpenStreetMap</a>, under{' '}
      <a href="http://www.openstreetmap.org/copyright">ODbL</a>.
    </span>
  )

  const WikimediaAttribution = () => (
    <span className="map-attribution">
      Map tiles by{' '}
      <a href="https://foundation.wikimedia.org/w/index.php?title=Maps_Terms_of_Use#Where_does_the_map_data_come_from.3F">
        Wikimedia
      </a>
      . Data by <a href="http://openstreetmap.org">OpenStreetMap</a>
    </span>
  )

  return (
    <Container className={classes.root}>
      <Map
        limitBounds="edge"
        center={center}
        zoom={zoom}
        provider={providers['stamen']}
        dprs={[1, 2]}
        onBoundsChanged={handleBoundsChange}
        onClick={() => setDialogShown(false)}
        animate={true}
        zoomSnap={zoomSnap}
        mouseEvents={mouseEvents}
        touchEvents={touchEvents}
        minZoom={minZoom}
        maxZoom={maxZoom}
        height={600}
        boxClassname="pigeon-filters"
        attribution={
          provider === 'stamen' ? (
            <StamenAttribution />
          ) : provider === 'wikimedia' ? (
            <WikimediaAttribution />
          ) : null
        }>
        {props.appointmentReducer.appointmentList &&
          props.appointmentReducer.appointmentList.map((appointment) => {
            return (
              appointment.latitude && (
                <Marker
                  key={appointment.appointmentId}
                  anchor={[appointment.latitude, appointment.longitude]}
                  payload={appointment}
                  onClick={handleMarkerClick}
                />
              )
            )
          })}

        {dialogShown && (
          <Overlay
            anchor={card.anchor}
            address={card.address}
            city={card.city}
            postalCode={card.postalCode}
            alarmType={card.alarmType}
            onClick={() => setDialogShown(false)}
          />
        )}
        <div className={classes.zoomContainer}>
          <IconButton
            aria-label="zoom-in"
            className={classes.iconButton}
            onClick={() => setZoom(Math.min(zoom + 1, MAX_ZOOM))}>
            <AddBoxIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            className={classes.iconButton}
            onClick={() => setZoom(Math.max(zoom - 1, MIN_ZOOM))}>
            <IndeterminateCheckBoxIcon />
          </IconButton>
        </div>
      </Map>
      <LoadingIndicator open={props.appointmentReducer.isLoading} />
    </Container>
  )
}

const mapStateToProps = (state) => {
  const {appointmentReducer} = state
  return {appointmentReducer}
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => dispatch(action)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentScreen)
