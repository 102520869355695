import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import RestRequester from '../../api/RestRequester'
import * as actions from './actions'
import * as types from './types'

function* fetchTeamList(action) {
  try {
    const teams = yield call(() => RestRequester.getTeamList(action.payload))
    yield put(actions.fetchTeamsSuccess(teams))
  } catch {
    yield put(actions.fetchTeamsFailed())
  }
}

function* fetchTeamListPaged(action) {
  try {
    const teams = yield call(() =>
      RestRequester.getTeamListPaged(action.payload.pageable, action.payload.filter)
    )
    yield put(actions.fetchTeamsPagedSuccess(teams))
  } catch {
    yield put(actions.fetchTeamsPagedFailed())
  }
}

function* fetchTeamDetail(action) {
  try {
    const team = yield call(() => RestRequester.getTeamDetail(action.payload.employeeId))
    yield put(actions.fetchTeamsDetailSuccess(team))
  } catch {
    yield put(actions.fetchTeamsDetailFailed())
  }
}

function* saveTeamDetail(action) {
  console.log('save team detail', action)
  try {
    if (action.payload.team.teamId) {
      yield call(() => RestRequester.updateTeamDetail(action.payload.team))
    } else {
      yield call(() => RestRequester.insertTeamDetail(action.payload.team))
    }
    yield put(
      actions.saveTeamsDetailSuccess(
        action.payload.pagination,
        action.payload.filter,
        action.payload.form
      )
    )
  } catch {
    yield put(actions.clearTeamDetail())
    yield put(actions.saveTeamsDetailFailed())
  }
}

function* saveTeamDetailSuccess(action) {
  const {pagination, filter, form} = action.payload
  yield put(actions.clearTeamDetail())
  yield put(actions.fetchTeamsRequested())
  yield put(actions.fetchTeamsPagedRequested(pagination, filter))
  form.reset()
}

function* deleteTeam(action) {
  try {
    const team = yield call(() => RestRequester.deleteTeam(action.payload.teamId))
    const history = action.payload.history
    yield put(actions.deleteTeamsDetailSuccess(action.payload.pagination, action.payload.filter))
  } catch (error) {
    console.error('response from server', error.response)
    const msg = error.response?.data || ''
    yield put(actions.deleteTeamsDetailFailed(msg))
  }
}

function* deleteTeamSuccess(action) {
  yield put(actions.fetchTeamsRequested())
  yield put(actions.fetchTeamsPagedRequested(action.payload.pagination, action.payload.filter))
}

function* saga() {
  yield all([
    takeEvery(types.TEAM_REQUESTED, fetchTeamList),
    takeEvery(types.TEAM_PAGED_REQUESTED, fetchTeamListPaged),
    takeEvery(types.TEAM_DETAIL_REQUESTED, fetchTeamDetail),
    takeEvery(types.TEAM_DETAIL_SAVE_REQUESTED, saveTeamDetail),
    takeEvery(types.TEAM_DETAIL_SAVE_SUCCEEDED, saveTeamDetailSuccess),
    takeEvery(types.TEAM_DETAIL_DELETE_REQUESTED, deleteTeam),
    takeEvery(types.TEAM_DETAIL_DELETE_SUCCEEDED, deleteTeamSuccess)
  ])
}

export default saga
