import React, {useEffect, useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {useHistory, Link as RouterLink} from 'react-router-dom'
import {Form, Field} from 'react-final-form'
import {useTranslation} from 'react-i18next'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import DoneIcon from '@material-ui/icons/Done'

import ilustration from '../../assets/images/login_ilustration.png'
import * as actions from './actions'
import Sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import LoadingIndicator from '../../components/LoadingIndicator'
import TextFieldAdapter from '../../components/forms/TextFieldAdapter'
import {required, emailValidation} from '../../commons/validator'
import {ReduxActionType} from '../../commons/interfaces'

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: Colors.primary
  },
  btn: {
    marginTop: Sizes.largeMargin
  },
  container: {
    width: '100%',
    // display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    background: `linear-gradient(90deg, ${Colors.background} 50%, #FFFFFF 50%)`,
    color: Colors.text,
    marginBottom: 56,
    [theme.breakpoints.down('sm')]: {
      background: Colors.background
    }
  },
  copyright: {
    marginTop: '3rem',
    color: Colors.text
  },
  descriptionContainer: {
    padding: Sizes.xxlPadding,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
      width: '100%'
    }
  },
  download: {
    fontSize: '0.6rem',
    marginTop: Sizes.defaultMargin,
    marginBottom: Sizes.defaultMargin
  },
  logo: {
    width: '100px',
    margin: '16px'
  },
  form: {
    width: '100%'
  },
  formContainer: {
    paddingTop: Sizes.xlPadding,
    paddingBottom: Sizes.xlPadding,
    paddingRight: Sizes.xxlPadding,
    paddingLeft: Sizes.xxlPadding,
    [theme.breakpoints.down('sm')]: {
      background: Colors.background,
      width: '100%'
    }
  },
  ilustration: {
    width: '100%'
  },
  inputText: {
    maxWidth: '300px',
    marginBottom: Sizes.defaultMargin
  },
  listContainer: {
    marginBottom: Sizes.largeMargin
  },
  listText: {
    marginLeft: Sizes.largeMargin
  },
  logoContainer: {
    marginRight: '1rem'
  },
  resetPassword: {
    marginTop: Sizes.defaultMargin,
    fontSize: '0.8rem'
  },
  title: {
    color: Colors.secondary
  },
  toolbar: {
    color: 'white',
    fontSize: '2rem'
  }
}))

interface ScreenProps {
  authReducer: any
  dispatch: (action: ReduxActionType) => void
}

const LoginScreen: React.FC<ScreenProps> = (props) => {
  const classes = useStyles({})
  const history = useHistory()
  const {t, i18n} = useTranslation()

  useEffect(() => {
    document.title = 'SAFE | Login'
    let token = sessionStorage.getItem('token')
    if (token) {
      // RestRequester.setInterceptor(token)
      history.push('/medewerker')
    }
  }, [])

  const _onLoginClicked = (values: any) => {
    props.dispatch(actions.requestLogin(values.username, values.password, values.clientId, history))
  }

  return (
    <div className={classes.container}>
      {/* <Container> */}
      <Grid container spacing={0}>
        <Grid item md={6} className={classes.formContainer}>
          <h2 className={classes.title}>{t('auth.pageTitle')}</h2>
          <Form
            onSubmit={_onLoginClicked}
            render={(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <FormControl className={classes.form}>
                  <div>
                    <Field
                      name="clientId"
                      id="cientId"
                      label={t('auth.clientId')}
                      validate={(value) => required(value, i18n)}
                      className={classes.inputText}
                      component={TextFieldAdapter}
                      InputProps={{style: {backgroundColor: 'white'}}}
                      variant="outlined"
                      defaultValue={''}
                    />
                  </div>
                  <div>
                    <Field
                      id="username"
                      name="username"
                      label={t('auth.username')}
                      validate={(value) => emailValidation(value, i18n)}
                      className={classes.inputText}
                      component={TextFieldAdapter}
                      InputProps={{style: {backgroundColor: 'white'}}}
                      variant="outlined"
                      defaultValue={''}
                      // onChange={_handleChange}
                    />
                  </div>
                  <div>
                    <Field
                      id="password"
                      name="password"
                      label={t('auth.password')}
                      type="password"
                      validate={(value) => required(value, i18n)}
                      className={classes.inputText}
                      component={TextFieldAdapter}
                      InputProps={{style: {backgroundColor: 'white'}}}
                      variant="outlined"
                      defaultValue={''}
                      // onChange={_handleChange}
                    />
                  </div>
                  {props.authReducer.errorMsg && (
                    <Alert severity="error">{t(props.authReducer.errorMsg)}</Alert>
                  )}
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={formProps.submitting}>
                      {t('auth.login')}
                    </Button>
                  </div>
                </FormControl>
              </form>
            )}
          />
          <Typography className={classes.resetPassword}>
            <Link component={RouterLink} to="/resetPassword" color="secondary" underline="none">
              {t('auth.resetPassword')}
            </Link>
          </Typography>
          <Typography className={classes.download}>
            Ga naar de{' '}
            <Link component={RouterLink} to="/download">
              download pagina
            </Link>{' '}
            om de SAFE app te downloaden.
          </Typography>
          <h2 className={classes.title}>{t('auth.appointmentTitle')}</h2>
          <p>{t('auth.appointmentDesc')}</p>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => (window.location.href = 'https://safe-app.nl/boek-een-demo/')}>
            {t('auth.demoBtn')}
          </Button>
        </Grid>
        <Grid item md={6} className={classes.descriptionContainer}>
          <img alt="SAFE" src={ilustration} className={classes.ilustration} />
          <h2 className={classes.title}>{t('auth.loginTitle')}</h2>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            className={classes.listContainer}
            wrap="nowrap">
            <Grid item>
              <DoneIcon color="secondary" />
            </Grid>
            <Grid item className={classes.listText}>
              <div>{t('auth.loginList1')}</div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            className={classes.listContainer}
            wrap="nowrap">
            <Grid item>
              <DoneIcon color="secondary" />
            </Grid>
            <Grid item className={classes.listText}>
              {t('auth.loginList2')}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            className={classes.listContainer}
            wrap="nowrap">
            <Grid item>
              <DoneIcon color="secondary" />
            </Grid>
            <Grid item className={classes.listText}>
              {t('auth.loginList3')}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </Container> */}
      <LoadingIndicator open={props.authReducer.isLoading} />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const {authReducer} = state
  return {authReducer}
}

const mapDispatchToProps = (dispatch: (action: ReduxActionType) => void) => {
  return {
    dispatch: (action: ReduxActionType) => dispatch(action)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
