import React, {useEffect, useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {useHistory} from 'react-router-dom'
import {Form, Field} from 'react-final-form'
import {useTranslation} from 'react-i18next'
import * as actions from './actions'
import Sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import LoadingIndicator from '../../components/LoadingIndicator'
import TextFieldAdapter from '../../components/forms/TextFieldAdapter'

const useStyles = makeStyles({
  heading: {
    backgroundColor: Colors.primary,
    color: 'white',
    fontWeight: 'bolder',
    padding: '1rem',
    marginBottom: Sizes.defaultPadding
  },
  btn: {
    marginRight: '0.5rem'
  },
  btnContainer: {
    marginBottom: Sizes.defaultMargin
  },
  paragraph: {
    color: Colors.text,
    lineHeight: 1.5
  },
  list: {
    marginTop: 0
  },
  chkBox: {
    color: Colors.primary
  },
  inputField: {
    color: Colors.textLight
  }
})

const ChangePasswordScreen = props => {
  const classes = useStyles()
  const history = useHistory()
  const {t, i18n} = useTranslation()

  useEffect(() => {
    return function cleanup() {
      props.dispatch(actions.clearMsg())
    }
  }, [])

  const _onChangePassword = values => {
    props.dispatch(actions.requestChangePassword(values.oldPassword, values.newPassword, history))
  }

  const _onBackPressed = () => {
    // props.dispatch(actions.removeEmployeeDetail())
    history.goBack()
  }

  const zxcvbn = require('zxcvbn')
  return (
    <Container className={classes.root}>
      {props.authReducer.errorMsg && (
        <Alert severity="error" onClose={() => props.dispatch(actions.clearMsg())}>
          {props.authReducer.errorMsg}
        </Alert>
      )}
      <Form
        onSubmit={_onChangePassword}
        reducer={props.authReducer}
        validate={values => {
          const errors = {}

          if (!values.newPassword) {
            errors.newPassword = t('common.validation.required')
          } else {
            const passwordCheck = zxcvbn(values.newPassword)
            const re = /((?=.*\d)(?=.*[A-Z])(?=.*\w)).{8,}/
            const regexMatch = re.test(values.newPassword)
            if (passwordCheck.score < 2 || !regexMatch) {
              errors.newPassword = t('common.validation.passwordNotValid')
            }
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = t('common.validation.required')
          } else if (values.confirmPassword !== values.newPassword) {
            errors.confirmPassword = t('common.validation.passwordNotMatch')
          }
          if (!values.oldPassword) {
            errors.oldPassword = t('common.validation.required')
          }
          return errors
        }}
        render={props => (
          <Grid item md={9} className={classes.section}>
            <Typography className={classes.heading}>Wachtwoord veranderen</Typography>
            <div className={classes.paragraph}>
              <Typography variant="body1">
                Stel hieronder een nieuw wachtwoord in. Het nieuwe wachtwoord moet aan de volgende
                eisen voldoen:
              </Typography>
              <ul className={classes.list}>
                <li>Minstens 8 karakters bevatten</li>
                <li>Minstens 1 cijfer bevatten</li>
                <li>Minstens 1 hoofdletter bevatten</li>
                <li>
                  Niet makkelijk te raden zijn. Je ziet een groen vinkje als het wachtwoord sterk
                  genoeg is
                </li>
              </ul>
            </div>
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <Field
                    name="newPassword"
                    id="newPassword"
                    label="Nieuw wachtwoord"
                    component={TextFieldAdapter}
                    className={classes.inputField}
                    InputProps={{style: {backgroundColor: 'white'}}}
                    variant="outlined"
                    type="password"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Herhaal nieuw wachtwoord"
                    className={classes.inputField}
                    component={TextFieldAdapter}
                    type="password"
                    InputProps={{style: {backgroundColor: 'white'}}}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <Field
                    id="oldPassword"
                    name="oldPassword"
                    label="Oud wachtwoord"
                    className={classes.inputField}
                    component={TextFieldAdapter}
                    type="password"
                    InputProps={{style: {backgroundColor: 'white'}}}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Button
                    disabled={props.submitting}
                    variant="contained"
                    color="primary"
                    size="medium"
                    type="submit"
                    className={classes.btn}>
                    Opslaan
                  </Button>
                  <Button
                    disabled={props.submitting}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    className={classes.btn}
                    onClick={_onBackPressed}>
                    Annuleren
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        )}
      />

      <LoadingIndicator open={props.authReducer.isLoading} />
    </Container>
  )
}

ChangePasswordScreen.propTypes = {
  authReducer: PropTypes.object
}

const mapStateToProps = state => {
  const {authReducer} = state
  return {authReducer}
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => dispatch(action)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordScreen)
