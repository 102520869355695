import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {AnyObject, Form} from 'react-final-form'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {TextField} from 'mui-rff'
import Sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import Constants from '../../commons/constants/constants'
import {ReduxActionType} from '../../commons/interfaces'
import UploadButton from '../../components/UploadButton'
import * as actions from './actions'
import {SettingsState} from './types'
import {CircularProgress, LinearProgress} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import classes from '*.module.css'

export interface Props {
  settingsReducer: SettingsState
  dispatch: (action: ReduxActionType) => void
}

const useStyles = makeStyles({
  header: {
    backgroundColor: Colors.primary,
    color: 'white',
    fontWeight: 'bolder',
    padding: '1rem',
    marginBottom: Sizes.defaultPadding
  },
  inlineBtn: {
    marginRight: Sizes.defaultMargin
  },
  progressContainer: {
    marginTop: -1 * Sizes.defaultMargin,
    marginBottom: Sizes.defaultMargin
  },
  section: {
    marginTop: Sizes.defaultMargin,
    marginBottom: Sizes.largeMargin
  }
})

const useAlarm112Styles = makeStyles({
  header: {
    backgroundColor: Colors.primary,
    color: 'white',
    fontWeight: 'bolder',
    padding: '1rem',
    marginBottom: Sizes.defaultPadding
  },
  loadingContainer: {
    margin: Sizes.defaultMargin
  }
})

export interface ErrorType {
  orgName?: string
}

export interface DialogProps {
  isShown: boolean
  msg: string
  onConfirm: () => void
  onCancel: () => void
}

export interface Alarm112SettingsProps {
  isDisabled: boolean
  onDisabledChange: (isDisabled: boolean) => void
  loading: boolean
}

const Alarm112Settings: React.FC<Alarm112SettingsProps> = (props) => {
  const {t} = useTranslation()
  const classes = useAlarm112Styles()

  return (
    <Grid item md={9}>
      <Typography className={classes.header}>{t('settings.alarmOptions')}</Typography>
      <Grid container spacing={2}>
        <FormControlLabel
          control={
            <Switch
              checked={!props.isDisabled}
              onChange={(event, checked) => {
                props.onDisabledChange(!checked)
              }}
              name="alarm-112-checkbox"
              color="primary"
              disabled={props.loading}
            />
          }
          label={t('settings.alarm112')}
          labelPlacement="start"
        />
        {props.loading && <CircularProgress size="16px" className={classes.loadingContainer} />}
      </Grid>
    </Grid>
  )
}

const OrganizationalSettingsScreen: React.FC<Props> = (props) => {
  const emptyDialog: DialogProps = {
    isShown: false,
    msg: '',
    onConfirm: () => {},
    onCancel: () => {}
  }
  const {t} = useTranslation()
  const classes = useStyles()
  const [logo, setLogo] = useState<File | undefined>()
  const [dialogProps, setDialogProps] = useState<DialogProps>(emptyDialog)

  useEffect(() => {
    props.dispatch(actions.requestOrgSettings())
  }, [])

  const _onUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input: HTMLInputElement = event.target
    const files = input.files

    if (files !== null) {
      const file: File = files[0]
      console.log('image size: ', file.size)
      if (!file.type.includes('image')) {
        props.dispatch(actions.showLogoError('settings.wrongFileType'))
      } else if (file.size <= Constants.MAX_DEFAULT_LOGO_SIZE) {
        setLogo(file)
      } else {
        props.dispatch(actions.showLogoError('settings.logoTooLarge'))
      }
    }
  }

  const _saveOrgName = (values: AnyObject) => {
    console.log('_saveOrgName', values)
    switch (values.action) {
      case 'save':
        if (values.orgName) {
          props.dispatch(actions.saveOrgName(values.orgName))
        } else {
          props.dispatch(actions.showNameError('settings.emptyName'))
        }

        break
      case 'edit':
        props.dispatch(actions.editOrgName(true))
        break
      case 'delete':
        // showDeleteDialog(true)
        setDialogProps({
          isShown: true,
          msg: t('settings.deleteNameConfirmationMsg'),
          onCancel: () => setDialogProps(emptyDialog),
          onConfirm: () => {
            setDialogProps(emptyDialog)
            props.dispatch(actions.removeOrgName())
          }
        })
        break
      case 'cancel':
        props.dispatch(actions.editOrgName(false))
        break
    }
  }

  const _onAlarm112Changed = (isDisabled: boolean) => {
    if (!isDisabled) {
      props.dispatch(actions.updateDisable112(isDisabled))
    } else {
      setDialogProps({
        isShown: true,
        msg: t('settings.disable112ConfirmationMsg'),
        onConfirm: () => {
          setDialogProps(emptyDialog)
          props.dispatch(actions.updateDisable112(isDisabled))
        },
        onCancel: () => setDialogProps(emptyDialog)
      })
    }
  }

  const _saveLogo = () => {
    if (logo != undefined && logo.size > 0 && logo.size <= Constants.MAX_DEFAULT_LOGO_SIZE) {
      props.dispatch(actions.saveOrgLogo(logo, props.dispatch))
    } else {
      console.log('error logo is empty')
    }
  }

  const Logo = ({data}: {data: string}) => <img src={data} width="100%" />

  const WarningDialog = (props: DialogProps) => {
    return (
      <Dialog open={props.isShown}>
        <DialogTitle id="delete-dialog">{'SAFE'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.msg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus onClick={props.onConfirm}>
            {t('common.yes')}
          </Button>
          <Button color="primary" onClick={props.onCancel}>
            {t('common.no')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container>
      <Form
        onSubmit={_saveOrgName}
        initialValues={{
          orgName: props.settingsReducer.orgName
        }}
        render={(formProps) => (
          <Grid item md={9} className={classes.section}>
            <Typography className={classes.header}>{t('settings.orgName')}</Typography>
            {props.settingsReducer.isNameLoading && (
              <div className={classes.progressContainer}>
                <LinearProgress variant="indeterminate" color="secondary" />
              </div>
            )}
            {props.settingsReducer.nameErrorKey && (
              <Alert severity="error" onClose={() => props.dispatch(actions.showNameError(''))}>
                {t(props.settingsReducer.nameErrorKey)}
              </Alert>
            )}
            <form onSubmit={formProps.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item sm={8} xs={12}>
                  {props.settingsReducer.orgName && !props.settingsReducer.isNameEditable ? (
                    <p>{props.settingsReducer.orgName}</p>
                  ) : (
                    <TextField
                      name="orgName"
                      id="orgName"
                      label={t('settings.orgName')}
                      inputProps={{style: {backgroundColor: 'white'}, maxLength: 50}}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  {!props.settingsReducer.orgName || props.settingsReducer.isNameEditable ? (
                    <>
                      <Button
                        className={classes.inlineBtn}
                        disabled={props.settingsReducer.isNameLoading}
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => {
                          formProps.form.change('action', 'save')
                        }}
                        type="submit">
                        {t('common.save')}
                      </Button>
                      {props.settingsReducer.orgName && (
                        <Button
                          disabled={props.settingsReducer.isNameLoading}
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            formProps.form.change('action', 'cancel')
                          }}
                          type="submit">
                          {t('common.cancel')}
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        disabled={props.settingsReducer.isNameLoading}
                        className={classes.inlineBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          formProps.form.change('action', 'edit')
                        }}
                        size="medium"
                        type="submit">
                        {t('common.edit')}
                      </Button>
                      <Button
                        className={classes.inlineBtn}
                        disabled={props.settingsReducer.isNameLoading}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          formProps.form.change('action', 'delete')
                        }}
                        size="medium"
                        type="submit">
                        {t('common.delete')}
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
        )}
      />

      <Grid item md={9} className={classes.section}>
        <Typography className={classes.header}>{t('settings.orgLogo')}</Typography>
        {props.settingsReducer.uploadProgress && (
          <div className={classes.progressContainer}>
            <LinearProgress
              variant="determinate"
              value={props.settingsReducer.uploadProgress}
              color="secondary"
            />
          </div>
        )}
        {props.settingsReducer.isLogoLoading && !props.settingsReducer.uploadProgress && (
          <div className={classes.progressContainer}>
            <LinearProgress variant="indeterminate" color="secondary" />
          </div>
        )}
        {props.settingsReducer.logoErrorKey && (
          <Alert severity="error" onClose={() => props.dispatch(actions.showLogoError(''))}>
            {t(props.settingsReducer.logoErrorKey)}
          </Alert>
        )}
        {!props.settingsReducer.isLogoEditable && (
          <Grid item sm={4}>
            <Logo data={props.settingsReducer.orgLogo} />
          </Grid>
        )}
        {!props.settingsReducer.orgLogo || props.settingsReducer.isLogoEditable ? (
          <>
            <Grid item sm={8}>
              <UploadButton
                accept=".jpg,.jpeg,.gif,.png"
                label={logo ? logo.name : t('settings.upload')}
                onChange={_onUploadChange}
              />
            </Grid>
            <Button
              className={classes.inlineBtn}
              disabled={props.settingsReducer.isNameLoading}
              variant="contained"
              color="primary"
              size="medium"
              onClick={_saveLogo}
              type="submit">
              {t('settings.upload')}
            </Button>

            {props.settingsReducer.orgLogo && (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => {
                  props.dispatch(actions.editOrgLogo(false))
                }}
                type="submit">
                {t('common.cancel')}
              </Button>
            )}
          </>
        ) : (
          <>
            <Button
              disabled={props.settingsReducer.isNameLoading}
              className={classes.inlineBtn}
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => {
                console.log('trying to dispatch edit action')
                setLogo(undefined)
                props.dispatch(actions.editOrgLogo(true))
              }}
              type="submit">
              {t('common.edit')}
            </Button>
            <Button
              onClick={() => {
                // showDeleteLogoDialog(true)}
                setDialogProps({
                  isShown: true,
                  msg: t('settings.deleteLogoConfirmationMsg'),
                  onCancel: () => setDialogProps(emptyDialog),
                  onConfirm: () => {
                    setDialogProps(emptyDialog)
                    props.dispatch(actions.removeOrgLogo())
                  }
                })
              }}
              className={classes.inlineBtn}
              disabled={props.settingsReducer.isNameLoading}
              variant="outlined"
              color="primary"
              size="medium"
              type="submit">
              {t('common.delete')}
            </Button>
          </>
        )}
      </Grid>
      <Alarm112Settings
        loading={props.settingsReducer.isAlarm112Loading}
        isDisabled={props.settingsReducer.isAlarm112Disabled}
        onDisabledChange={_onAlarm112Changed}
      />
      <WarningDialog
        isShown={dialogProps.isShown}
        onConfirm={dialogProps.onConfirm}
        msg={dialogProps.msg}
        onCancel={dialogProps.onCancel}
      />
    </Container>
  )
}

const mapStateToProps = (state: any) => {
  const {settingsReducer} = state
  return {settingsReducer}
}

const mapDispatchToProps = (dispatch: (action: ReduxActionType) => void) => {
  return {
    dispatch: (action: ReduxActionType) => dispatch(action)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationalSettingsScreen)
