const sizes = {
  defaultPadding: 8,
  largePadding: 16,
  xlPadding: 32,
  xxlPadding: 64,
  defaultMargin: 8,
  largeMargin: 16
}

export default sizes
