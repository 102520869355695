import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import RestRequester from '../../api/RestRequester'
import {hasRole} from '../../commons/utils'
import constants from '../../commons/constants/constants'
import ReleaseScreenView from './ReleaseScreenView'
import ReleaseItem from './ReleaseItem'
import {ReleaseNote, MobileApplication} from './types'
import {UserRole} from '../../commons/enums'

export interface Props {}

const ReleaseScreen: React.FC<Props> = (props) => {
  const [notes, setNotes] = useState<ReleaseNote[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isDeleteDialogShown, showDeleteDialog] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [noteId, setNoteId] = useState<number>(0)

  const history = useHistory()
  const {t} = useTranslation()

  const jsonStringUser: string | null = sessionStorage.getItem('loggedInUser')
  const loggedInUser = jsonStringUser ? JSON.parse(jsonStringUser) : ''

  useEffect(() => {
    setLoading(true)
    RestRequester.listReleaseNote()
      .then((response) => {
        setLoading(false)
        const payload = response.data
        if (payload.success) {
          setNotes(payload.data)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error(error.response)
      })
  }, [])

  const _doDeleteEmployee = () => {
    showDeleteDialog(false)
    setLoading(true)
    const id = noteId
    setNoteId(0)
    if (id) {
      RestRequester.deleteReleaseNote(id)
        .then((response) => {
          setLoading(false)
          if (response.status === 200) {
            const payload = response.data
            if (payload.success) {
              history.go(0)
            } else {
            }
          }
        })
        .catch((error) => {
          console.error(error.response)
          setLoading(false)
        })
    }
  }

  const _onUploadApp = (file: File, app: MobileApplication) => {
    RestRequester.uploadMobileApp(app, file, _uploadProgressHandler)
      .then((response) => {
        history.go(0)
      })
      .catch((error) => console.log('error uploading app', error))
  }

  const _onDeleteApp = (app: MobileApplication) => {
    RestRequester.deleteApp(app.id)
      .then((response) => {
        history.go(0)
      })
      .catch((error) => console.log('error deleting app', error))
  }

  const _uploadProgressHandler = (progressEvent: any) => {
    const totalLength = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader('content-length') ||
        progressEvent.target.getResponseHeader('x-decompressed-content-length')
    if (totalLength !== null) {
      setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength))
    }
  }

  return (
    <ReleaseScreenView
      isAdmin={loggedInUser && hasRole(loggedInUser.systemRoles, UserRole.ADMIN)}
      isDeleteDialogShown={isDeleteDialogShown}
      onAddNote={() => history.push('/release/detail')}
      onDeleteConfirm={_doDeleteEmployee}
      onDeleteCancel={() => showDeleteDialog(false)}
      isLoading={isLoading}>
      {notes.map((note, i) => {
        return (
          <ReleaseItem
            key={note.id}
            note={note}
            isAdmin={loggedInUser && hasRole(loggedInUser.systemRoles, UserRole.ADMIN)}
            onDeleteNote={() => {
              showDeleteDialog(true)
              setNoteId(note.id)
            }}
            onEditNote={() => history.push('/release/detail', {releaseId: note.id})}
            onUploadApp={_onUploadApp}
            onDeleteApp={(type, app) => _onDeleteApp(app)}
            drawDivider={notes.length !== i + 1}
            onLoadApps={(version) => {
              return RestRequester.getAppByVersion(version)
            }}
            uploadProgress={uploadProgress}
          />
        )
      })}
    </ReleaseScreenView>
  )
}

export default ReleaseScreen
