import {Employee, Team} from '../../commons/interfaces'

export interface ReceiverScreenState {
  isLoading: boolean
  listEmployee: Array<Employee>
  listTeam: Array<Team>
  totalEmployee: number
}

export const EMPLOYEE_ADD = 'safe/employee/add'
export const EMPLOYEE_REMOVE = 'safe/employee/remove'
export const EMPLOYEE_RESET_PASSWORD_BY_ADMIN_REQUESTED =
  'safe/employee/reset_password_by_admin_requested'
export const EMPLOYEE_RESET_PASSWORD_BY_ADMIN_SUCCEEDED =
  'safe/employee/reset_password_by_admin_succeeded'
export const EMPLOYEE_RESET_PASSWORD_BY_ADMIN_FAILED =
  'safe/employee/reset_password_by_admin_failed'
export const EMPLOYEE_RESET_PASSWORD_LOGIN = 'safe/employee/reset_password_login'
export const EMPLOYEE_CLEAR_RESET_PASSWORD_LOGIN = 'safe/employee/clear_reset_password_login'
export const EMPLOYEES_FETCH_SUCCEEDED = 'safe/employee/fetch_succeed'
export const EMPLOYEES_FETCH_FAILED = 'safe/employee/fetch_failed'
export const EMPLOYEES_FETCH_REQUESTED = 'safe/employee/fetch_requested'
export const EMPLOYEE_DETAIL_FETCH_SUCCEEDED = 'safe/employee/detail/fetch_succeed'
export const EMPLOYEE_DETAIL_FETCH_FAILED = 'safe/employee/detail/fetch_failed'
export const EMPLOYEE_DETAIL_FETCH_REQUESTED = 'safe/employee/detail/fetch_requested'
export const EMPLOYEE_DETAIL_ADD = 'safe/employee/detail/add'
export const EMPLOYEE_DETAIL_REMOVE = 'safe/employee/detail/remove'
export const EMPLOYEE_DETAIL_UPDATE_SUCCEEDED = 'safe/employee/detail/update_succeed'
export const EMPLOYEE_DETAIL_UPDATE_FAILED = 'safe/employee/detail/update_failed'
export const EMPLOYEE_DETAIL_UPDATE_REQUESTED = 'safe/employee/detail/update_requested'
export const EMPLOYEE_TEAMS_FETCH_REQUESTED = 'safe/employee/team/fetch_requested'
export const EMPLOYEE_TEAMS_FETCH_SUCCEEDED = 'safe/employee/team/fetch_succeed'
export const EMPLOYEE_TEAMS_FETCH_FAILED = 'safe/employee/team/fetch_failed'
export const EMPLOYEE_DETAIL_SAVE_SUCCEEDED = 'safe/employee/detail/save_succeed'
export const EMPLOYEE_DETAIL_SAVE_FAILED = 'safe/employee/detail/save_failed'
export const EMPLOYEE_DETAIL_SAVE_REQUESTED = 'safe/employee/detail/save_requested'
export const EMPLOYEE_DETAIL_DELETE_SUCCEEDED = 'safe/employee/detail/delete_succeed'
export const EMPLOYEE_DETAIL_DELETE_FAILED = 'safe/employee/detail/delete_failed'
export const EMPLOYEE_DETAIL_DELETE_REQUESTED = 'safe/employee/detail/delete_requested'
export const EMPLOYEE_UPLOAD_REQUESTED = 'safe/employee/upload_requested'
export const EMPLOYEE_UPLOAD_SUCCEEDED = 'safe/employee/upload_succeeded'
export const EMPLOYEE_UPLOAD_FAILED = 'safe/employee/upload_failed'
export const EMPLOYEE_UPLOAD_EMPTY = 'safe/employee/upload_empty'
export const EMPLOYEE_BUTTON_FETCH_REQUESTED = 'safe/employee/button/fetch_requested'
export const EMPLOYEE_BUTTON_FETCH_SUCCEEDED = 'safe/employee/button/fetch_succeeded'
export const EMPLOYEE_BUTTON_FETCH_FAILED = 'safe/employee/button/fetch_failed'
export const ERROR_MSG_CLEAR = 'safe/employee/clear_error'
export const SUCCESS_MSG_CLEAR = 'safe/employee/clear_success'
