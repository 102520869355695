import React from 'react'
import Button from '@material-ui/core/Button'
import MUIDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import {useTranslation} from 'react-i18next'

export interface DialogProps {
  isShown: boolean
  msg: string
  onConfirm: () => void
  onCancel: () => void
}

export const Dialog = (props: DialogProps) => {
  const {t} = useTranslation()
  return (
    <MUIDialog open={props.isShown}>
      <DialogTitle id="delete-dialog">{'SAFE'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={props.onConfirm}>
          {t('common.yes')}
        </Button>
        <Button color="primary" onClick={props.onCancel}>
          {t('common.no')}
        </Button>
      </DialogActions>
    </MUIDialog>
  )
}
