import * as types from './types'

const requestAppointmentsWithAlarm = () => ({
  type: types.APPOINTMENT_WITH_ALARM_REQUESTED
})

const appointmentsWithAlarmSucceeded = appointmentList => ({
  type: types.APPOINTMENT_WITH_ALARM_SUCCEEDED,
  payload: {appointments: appointmentList}
})

const appointmentsWithAlarmFailed = msg => ({
  type: types.APPOINTMENT_WITH_ALARM_FAILED,
  payload: {msg}
})

const clearAppointmentWithAlarm = () => ({
  type: types.CLEAR_APPOINTMENT_WITH_ALARM
})

export {
  requestAppointmentsWithAlarm,
  appointmentsWithAlarmSucceeded,
  appointmentsWithAlarmFailed,
  clearAppointmentWithAlarm
}
