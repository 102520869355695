import React from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Container from '@material-ui/core/Container'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import {makeStyles} from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'
import Colors from '../commons/constants/colors'
import logo from '../assets/images/logo-white.png'
import * as actions from '../features/auth/actions'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import MapIcon from '@material-ui/icons/Map'
import NotificationIcon from '@material-ui/icons/NotificationsNoneOutlined'
import {connect} from 'react-redux'
import {hasRole} from '../commons/utils'
import {useTranslation} from 'react-i18next'

import urls from '../commons/constants/urls'
import {UserRole} from '../commons/enums'
import {useEmployeeAuthorization} from '../hooks/EmployeeIsAuthorized'

const useStyles = makeStyles({
  navBar: {
    marginTop: '1rem',
    marginBottom: '1rem',
    backgroundColor: Colors.background
  },
  tab: {
    display: 'inline-flex',
    flexDirection: 'row'
  },
  appBar: {
    backgroundColor: Colors.primary
  },
  toolbar: {
    color: 'white',
    fontSize: '2rem'
  },
  logoContainer: {
    marginRight: '1rem'
  },
  rightMenu: {
    position: 'relative',
    marginLeft: 'auto'
  },
  menuBtn: {
    color: 'white',
    textTransform: 'none'
  },
  peopleIcon: {
    color: 'white'
  }
})

const Header = (props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const {t} = useTranslation()
  const isClientAdminAndUp = useEmployeeAuthorization([UserRole.ADMIN, UserRole.CLIENT_ADMIN])
  const isSuperAdmin = useEmployeeAuthorization([UserRole.ADMIN])
  const _checkPathName = () => {
    const {pathname} = props.history.location
    let topPath = `/${pathname.split('/')[1]}`

    switch (topPath) {
      case '/medewerker':
      case '/team':
      case '/rapport':
      case '/release':
      case '/dashboard':
      case '/heatmap':
      case '/locatie':
      case urls.alarmReceiverPage:
        break
      default:
        topPath = false
        break
    }
    return topPath
  }

  const _handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const _handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const _handleCallToRouter = (event, value) => {
    props.history.push(value)
  }

  const user = JSON.parse(sessionStorage.getItem('loggedInUser'))
  return (
    <header id="header">
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Container>
          <Toolbar className={classes.toolbar} disableGutters={true}>
            <div className={classes.logoContainer}>
              <img alt="SAFE" src={logo} width="36" height="44" />
            </div>
            <b>SAFE</b>
            {props.isPrivate && (
              <div className={classes.rightMenu}>
                <Button
                  className={classes.menuBtn}
                  aria-haspopup="true"
                  endIcon={<PersonOutlineOutlinedIcon className={classes.peopleIcon} />}
                  onClick={_handleOpenMenu}>
                  {user.username}
                  <ExpandMoreIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorEl)}
                  onClose={_handleCloseMenu}>
                  {isClientAdminAndUp && (
                    <MenuItem
                      onClick={() => {
                        props.history.push(urls.orgSettingsPage)
                        _handleCloseMenu()
                      }}>
                      {t('menu.orgSettings')}
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      props.history.push('/change-password')
                      _handleCloseMenu()
                    }}>
                    {t('menu.resetPassword')}
                  </MenuItem>
                  <MenuItem onClick={() => props.dispatch(actions.requestLogout(props.history))}>
                    <PowerSettingsNewIcon />
                    {t('menu.logOut')}
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {props.isPrivate && (
        <Container>
          <Paper square className={classes.navBar} elevation={0}>
            <Tabs
              value={_checkPathName()}
              indicatorColor="primary"
              textColor="primary"
              onChange={_handleCallToRouter}>
              <Tab
                icon={<PersonOutlineOutlinedIcon />}
                label="Medewerker"
                value={urls.employeePage}
                classes={{wrapper: classes.tab}}
              />
              <Tab
                label="Team"
                icon={<PeopleOutlineIcon />}
                classes={{wrapper: classes.tab}}
                value={urls.teamPage}
              />
              {isSuperAdmin && (
                <Tab
                  icon={<NotificationIcon />}
                  label="Alarm opvolger"
                  value={urls.alarmReceiverPage}
                  classes={{wrapper: classes.tab}}
                />
              )}
              {isClientAdminAndUp && (
                <Tab
                  icon={<ImportContactsIcon />}
                  label="Locatie"
                  value={urls.locationPage}
                  classes={{wrapper: classes.tab}}
                />
              )}

              <Tab
                label="Rapport"
                icon={<EqualizerIcon />}
                classes={{wrapper: classes.tab}}
                value={urls.reportPage}
              />
              <Tab
                label="Heatmap"
                icon={<MapIcon />}
                classes={{wrapper: classes.tab}}
                value={urls.heatmapPage}
              />
              <Tab label="Release Notes" value={urls.releasePage} />
              {isSuperAdmin && <Tab label="Dashboard" value={urls.dashboardPage} />}
            </Tabs>
          </Paper>
        </Container>
      )}
    </header>
  )
}
const mapStateToProps = (state) => {
  const {authReducer} = state
  return {authReducer}
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => dispatch(action)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
