import * as types from './types'

const INITIAL_STATE = {
  isLoading: false,
  errorMsg: '',
  dialog: {
    isShown: false,
    email: '',
    msg: ''
  },
  isResetTokenValid: false
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.AUTH_REQUESTED:
      return {...state, isLoading: true, username: action.payload.username, errorMsg: ''}
    case types.AUTH_SUCCEEDED:
      return {...state, isLoading: false, username: action.payload.username, errorMsg: ''}
    case types.AUTH_FAILED:
      return {...state, isLoading: false, errorMsg: action.payload.msg}
    case types.LOGOUT_REQUESTED:
      return {...state, isLoading: true}
    // case types.LOGOUT_SUCCEEDED:
    //   return {...state, isLoading: false}
    case types.LOGOUT_FAILED:
      return {...state, isLoading: false, errorMsg: action.payload.msg}
    case types.CHANGE_PASSWORD_REQUESTED:
      return {...state, isLoading: true}
    case types.CHANGE_PASSWORD_SUCCEEDED:
      return {...state, isLoading: false}
    case types.CHANGE_PASSWORD_FAILED:
      return {...state, isLoading: false, errorMsg: action.payload.msg}
    case types.VALIDATE_RESET_TOKEN_REQUESTED:
      return {...state, isLoading: true}
    case types.VALIDATE_RESET_TOKEN_SUCCEEDED:
      return {...state, isLoading: false, isResetTokenValid: true}
    case types.VALIDATE_RESET_TOKEN_FAILED:
      return {...state, isLoading: false, isResetTokenValid: false}
    case types.RESET_TOKEN_REQUESTED:
      return {...state, isLoading: true}
    case types.RESET_TOKEN_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        dialog: {
          isShown: true,
          email: action.payload.email
        }
      }
    case types.RESET_TOKEN_FAILED:
      return {...state, isLoading: false}
    case types.RESET_PASSWORD_BY_TOKEN_REQUESTED:
      return {...state, isLoading: true}
    case types.RESET_PASSWORD_BY_TOKEN_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        dialog: {
          isShown: true,
          email: '',
          msg:
            'Het wachtwoord is succesvol veranderd. Open de SAFE app om in te loggen met dit nieuwe wachtwoord.'
        }
      }
    case types.RESET_PASSWORD_BY_TOKEN_FAILED:
      return {...state, isLoading: false}
    case types.GET_LOGGED_IN_USER_REQUESTED:
      return {...state, isLoading: true}
    case types.GET_LOGGED_IN_USER_SUCCEEDED:
      return {...state, isLoading: false}
    case types.GET_LOGGED_IN_USER_FAILED:
      return {...state, isLoading: false, errorMsg: action.payload.msg}
    case types.CLEAR_MSG:
      return {...state, errorMsg: ''}
    case types.CLEAR_DIALOG:
      return {...state, dialog: {isShown: false, email: '', msg: ''}}
    default:
      return state
  }
}

export default reducer
