import {
  AUTH_REQUESTED,
  AUTH_SUCCEEDED,
  AUTH_FAILED,
  LOGOUT_REQUESTED,
  LOGOUT_SUCCEEDED,
  LOGOUT_FAILED,
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCEEDED,
  CHANGE_PASSWORD_FAILED,
  VALIDATE_RESET_TOKEN_REQUESTED,
  VALIDATE_RESET_TOKEN_SUCCEEDED,
  VALIDATE_RESET_TOKEN_FAILED,
  RESET_TOKEN_REQUESTED,
  RESET_TOKEN_SUCCEEDED,
  RESET_TOKEN_FAILED,
  RESET_PASSWORD_BY_TOKEN_REQUESTED,
  RESET_PASSWORD_BY_TOKEN_SUCCEEDED,
  RESET_PASSWORD_BY_TOKEN_FAILED,
  GET_LOGGED_IN_USER_REQUESTED,
  GET_LOGGED_IN_USER_SUCCEEDED,
  GET_LOGGED_IN_USER_FAILED,
  CLEAR_MSG,
  CLEAR_DIALOG
} from './types'

const requestLogin = (username, password, clientId, history) => ({
  type: AUTH_REQUESTED,
  payload: {username: username, password: password, clientId: clientId, history: history}
})

const loginSucceed = (token, username, history) => ({
  type: AUTH_SUCCEEDED,
  payload: {token, username, history}
})

const loginFailed = msg => ({
  type: AUTH_FAILED,
  payload: {msg: msg}
})

const requestLogout = history => ({
  type: LOGOUT_REQUESTED,
  payload: {history: history}
})

const logoutSucceeded = history => ({
  type: LOGOUT_SUCCEEDED,
  payload: {history: history}
})

const logoutFailed = msg => ({
  type: LOGOUT_FAILED,
  payload: {msg}
})

const requestChangePassword = (oldPass, newPass, history) => ({
  type: CHANGE_PASSWORD_REQUESTED,
  payload: {oldPass: oldPass, newPass: newPass, history: history}
})

const changePasswordSucceeded = history => ({
  type: CHANGE_PASSWORD_SUCCEEDED,
  payload: {history: history}
})

const changePasswordFailed = msg => ({
  type: CHANGE_PASSWORD_FAILED,
  payload: {msg}
})

const requestValidateResetToken = (token, clientId, history) => ({
  type: VALIDATE_RESET_TOKEN_REQUESTED,
  payload: {token: token, clientId: clientId, history: history}
})

const validateResetTokenSucceeded = history => ({
  type: VALIDATE_RESET_TOKEN_SUCCEEDED,
  payload: {history: history}
})

const validateResetTokenFailed = msg => ({
  type: VALIDATE_RESET_TOKEN_FAILED,
  payload: {msg}
})

const requestResetToken = (clientId, email, history) => ({
  type: RESET_TOKEN_REQUESTED,
  payload: {clientId: clientId, email: email, history: history}
})

const resetTokenSucceeded = (email, history) => ({
  type: RESET_TOKEN_SUCCEEDED,
  payload: {email: email, history: history}
})

const resetTokenFailed = msg => ({
  type: RESET_TOKEN_FAILED,
  payload: {msg}
})

const requestResetPasswordByToken = (token, clientId, password, history) => ({
  type: RESET_PASSWORD_BY_TOKEN_REQUESTED,
  payload: {token: token, clientId: clientId, password: password, history: history}
})

const resetPasswordByTokenSucceeded = (email, history) => ({
  type: RESET_PASSWORD_BY_TOKEN_SUCCEEDED,
  payload: {email: email, history: history}
})

const resetPasswordByTokenFailed = msg => ({
  type: RESET_PASSWORD_BY_TOKEN_FAILED,
  payload: {msg}
})

const requestLoggedInUser = history => ({
  type: GET_LOGGED_IN_USER_REQUESTED,
  payload: {history}
})

const requestLoggedInUserSuccess = (user, history) => ({
  type: GET_LOGGED_IN_USER_SUCCEEDED,
  payload: {user, history}
})

const requestLoggedInUserFailed = msg => ({
  type: GET_LOGGED_IN_USER_FAILED,
  payload: {msg}
})

const clearMsg = () => ({
  type: CLEAR_MSG
})

const clearDialog = () => ({
  type: CLEAR_DIALOG
})

export {
  requestLogin,
  loginSucceed,
  loginFailed,
  requestLogout,
  logoutSucceeded,
  logoutFailed,
  requestChangePassword,
  changePasswordSucceeded,
  changePasswordFailed,
  requestValidateResetToken,
  validateResetTokenSucceeded,
  validateResetTokenFailed,
  requestResetToken,
  resetTokenSucceeded,
  resetTokenFailed,
  requestResetPasswordByToken,
  resetPasswordByTokenSucceeded,
  resetPasswordByTokenFailed,
  requestLoggedInUser,
  requestLoggedInUserSuccess,
  requestLoggedInUserFailed,
  clearMsg,
  clearDialog
}
