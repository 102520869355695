const Constants = {
  defaultEmployeeFilter: {
    firstName: '',
    lastName: '',
    teams: [],
    email: '',
    lastTimeOnline: 0,
    status: 0,
    batteryLevel: 0
  },
  defaultEmployeePagination: {
    draw: 1,
    page: 0,
    size: 10,
    sort: 'firstName,asc'
  },
  defaultTeamPagination: {
    draw: 1,
    page: 0,
    size: 10,
    sort: 'name,asc'
  },
  defaultAddressPagination: {
    draw: 1,
    page: 0,
    size: 10,
    sort: 'address,asc'
  },
  defaultAddressFilter: {
    address: '',
    additionalInfo: ''
  },
  MAX_TEAM_AMOUNT: 5,
  MAX_DEFAULT_LOGO_SIZE: 500000
}

export default Constants
