import React, {useState} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import {makeStyles} from '@material-ui/core/styles'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import IconButton from '@material-ui/core/IconButton'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import Colors from '../../commons/constants/colors'
import Sizes from '../../commons/constants/sizes'
import {Employee, ReduxActionType, Team} from '../../commons/interfaces'

export interface Props {
  employee: Employee
  batteryLevel?: string
  dispatch: (action: ReduxActionType) => void
  isBatteryLoading?: Array<boolean>
  isDAR?: boolean
  onEditEmployee: (employeeId: number) => void
  onDeleteEmployee: (employeeId: number) => void
  onChangePassword: (employeeId: number) => void
  onRowExpanded?: (employeeId: number) => void
}

const useStyles = makeStyles({
  actionButton: {
    textAlign: 'right'
  },
  row: {
    color: '#848484',
    borderBottomWidth: '0px'
  },
  rowBottom: {
    color: '#848484'
  },
  primaryTitle: {
    backgroundColor: '#3f51b5',
    color: 'white',
    padding: '1rem 0.75rem',
    margin: 0
  },
  containedTitle: {
    backgroundColor: '#F5F6FA',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  label: {
    fontWeight: 'bold'
  },
  plainTitle: {
    backgroundColor: 'white',
    color: '#3f51b5',
    padding: '1rem 0.75rem',
    margin: '0.5rem'
  },
  table: {
    backgroundColor: 'white'
  },
  tableHead: {
    backgroundColor: Colors.lightGray,
    borderTop: '1px solid',
    borderTopColor: Colors.gray,
    borderBottom: '2px solid',
    borderBottomColor: Colors.gray
  },
  tableHeadCell: {
    color: Colors.primary,
    fontWeight: 'bold'
  },
  tCellIcon: {
    width: '35px',
    textAlign: 'center',
    paddingLeft: Sizes.defaultPadding,
    paddingRight: Sizes.defaultPadding
  },
  tRowDetail: {
    backgroundColor: Colors.background
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    color: Colors.text,
    fontSize: '1rem'
  }
})

const _parseTeam = (teamDTOs: Array<Team>, t: any) => {
  if (Array.isArray(teamDTOs) && teamDTOs.length > 1) {
    return t('employee.multipleTeam')
  }

  return teamDTOs[0].name
}

const EmployeeTableRow: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)
  const {t} = useTranslation()

  const _parseUTC = (utc: string) => {
    const dateObj = new Date(utc)
    const date = ('0' + dateObj.getDate()).slice(-2)
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
    const year = dateObj.getFullYear()
    const time = ('0' + dateObj.getHours()).slice(-2) + ':' + ('0' + dateObj.getMinutes()).slice(-2)

    return `${date}-${month}-${year} ${time}`
  }

  let icEmployeeColor
  let statusString
  switch (props.employee.status) {
    case 'WORKING':
      icEmployeeColor = Colors.online
      statusString = 'Wel aan het werk'
      break
    case 'AT_CLIENT':
      icEmployeeColor = Colors.byClient
      statusString = 'Bij klant'
      break
    case 'ON_LEAVE':
      icEmployeeColor = Colors.text
      statusString = 'Out of office'
      break
    default:
      icEmployeeColor = Colors.text
      statusString = 'Niet aan het werk'
      break
  }

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell className={classes.tCellIcon}>
          <IconButton
            onClick={() => {
              if (!expand && props.onRowExpanded) {
                props.onRowExpanded(props.employee.employeeId)
              }
              setExpand(!expand)
            }}>
            <VisibilityOutlinedIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography className={classes.wrapIcon}>
            <PersonOutlineOutlinedIcon style={{color: icEmployeeColor}} />
            {[props.employee.firstName, props.employee.lastName].join(' ')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.wrapIcon}>{props.employee.email}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.wrapIcon}>
            {_parseTeam(props.employee.teamDTOs, t)}
          </Typography>
        </TableCell>
        <TableCell className={classes.actionButton}>
          <IconButton onClick={() => props.onEditEmployee(props.employee.employeeId)}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => props.onChangePassword(props.employee.employeeId)}>
            <LockOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => props.onDeleteEmployee(props.employee.employeeId)}>
            <DeleteOutlineIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      {expand && (
        <>
          <TableRow className={classes.tRowDetail}>
            <TableCell className={classes.row}></TableCell>
            <TableCell className={classes.row}>
              <b>{t('employee.status')}: </b>
              {statusString}
            </TableCell>
            <TableCell className={classes.row}>
              <b>{t('employee.phone')}: </b> {props.employee.phone}
            </TableCell>
            <TableCell className={classes.row} colSpan={2}>
              <b>{t('employee.lastOnline')}: </b>
              {props.employee.lastTimeOnline ? _parseUTC(props.employee.lastTimeOnline) : 'N/A'}
            </TableCell>
          </TableRow>
          {!props.isDAR && props.isBatteryLoading && (
            <TableRow className={classes.tRowDetail}>
              <TableCell></TableCell>
              <TableCell className={classes.rowBottom} colSpan={4}>
                <b>{t('battery.status') + ': '} </b>
                {props.isBatteryLoading[props.employee.employeeId] ? (
                  <CircularProgress size="0.8rem" />
                ) : (
                  t(`battery.${props.batteryLevel?.toLowerCase()}`)
                )}
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  const {employeeReducer} = state
  return {employeeReducer}
}

const mapDispatchToProps = (dispatch: (action: ReduxActionType) => void) => {
  return {
    dispatch: (action: ReduxActionType) => dispatch(action)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTableRow)
