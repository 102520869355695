import {combineReducers} from 'redux'
import employeeReducer from '../features/employee/reducer'
import authReducer from '../features/auth/reducer'
import teamReducer from '../features/team/reducer'
import downloadReducer from '../features/download/reducer'
import appointmentReducer from '../features/appointment/reducer'
import addressReducer from '../features/address/reducer'
import settingsReducer from '../features/settings/reducer'
import * as authTypes from '../features/auth/types'

const appReducer = combineReducers({
  employeeReducer,
  authReducer,
  teamReducer,
  downloadReducer,
  appointmentReducer,
  addressReducer,
  settingsReducer
})

const rootReducer = (state, action) => {
  if (action.type === authTypes.LOGOUT_SUCCEEDED) {
    state = undefined
  }

  return appReducer(state, action)
}
export default rootReducer
