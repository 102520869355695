import React, {useEffect, useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {Form, Field} from 'react-final-form'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import {useTranslation} from 'react-i18next'

import logo from '../../assets/images/logo.png'
import * as actions from './actions'
import Sizes from '../../commons/constants/sizes'
import Colors from '../../commons/constants/colors'
import LoadingIndicator from '../../components/LoadingIndicator'
import TextFieldAdapter from '../../components/forms/TextFieldAdapter'
import {required, emailValidation} from '../../commons/validator'
import i18n from '../../i18n'

const useStyles = makeStyles({
  btn: {
    marginTop: Sizes.largeMargin,
    marginBottom: 160
  },
  copyright: {
    marginTop: '3rem',
    color: Colors.text
  },
  download: {
    marginTop: '2rem',
    color: Colors.text,
    fontSize: '0.8em'
  },
  inputField: {
    color: Colors.textLight
  },
  logo: {
    width: '100px',
    margin: '16px'
  },
  form: {
    width: '100%',
    textAlign: 'center',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formReset: {
    width: '100%',
    textAlign: 'left'
  },
  formContainer: {
    textAlign: 'center',
    alignSelf: 'center',
    padding: 20,
    overflowY: 'scroll'
  },
  paragraph: {
    color: Colors.text,
    lineHeight: 1.5
  }
})

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ResetPasswordScreen = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const query = useQuery()
  const {t} = useTranslation()
  const zxcvbn = require('zxcvbn')

  const token = query.get('token')
  let clientId = query.get('client')

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  useEffect(() => {
    document.title = 'SAFE | Reset Password'
    props.dispatch(actions.requestValidateResetToken(token, clientId))
  }, [])

  const _onRequestResetToken = (values) => {
    if (values.clientId) {
      clientId = values.clientId
    }
    props.dispatch(actions.requestResetToken(clientId, values.email, history))
  }

  const _onChangePassword = (values) => {
    props.dispatch(
      actions.requestResetPasswordByToken(token, clientId, values.newPassword, history)
    )
  }

  return (
    <div className={classes.formContainer}>
      {!props.authReducer.isResetTokenValid || !token ? (
        <Form
          reducer={props.authReducer}
          onSubmit={_onRequestResetToken}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <FormControl className={classes.form}>
                <img alt="SAFE" src={logo} className={classes.logo} />
                <Typography variant="h4" style={{marginBottom: 12}}>
                  Reset Wachtwoord
                </Typography>
                <Typography variant="body1" style={{marginBottom: 12}}>
                  De reset link is verlopen. Klik op onderstaande knop om het wachtwoord opnieuw te
                  resetten.
                </Typography>
                <Field
                  id="email"
                  name="email"
                  label="E-mailadres"
                  // onChange={event => setUsername(event.target.value)}
                  fullWidth
                  component={TextFieldAdapter}
                  validate={(value) => emailValidation(value, i18n)}
                  variant="outlined"
                  InputProps={{style: {backgroundColor: 'white'}}}
                  style={{marginBottom: 12}}
                />
                {!clientId && (
                  <Field
                    id="clientId"
                    name="clientId"
                    label="Client ID"
                    // onChange={event => setUsername(event.target.value)}
                    fullWidth
                    component={TextFieldAdapter}
                    validate={(value) => required(value, i18n)}
                    variant="outlined"
                    InputProps={{style: {backgroundColor: 'white'}}}
                  />
                )}
                {props.reducer.errorMsg && <Alert severity="error">{props.reducer.errorMsg}</Alert>}
                <Button
                  disabled={props.submitting}
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  // onClick={_onLoginClicked}
                  type="submit"
                  fullWidth>
                  {t('employee.resetPassword')}
                </Button>
              </FormControl>
            </form>
          )}
        />
      ) : (
        <Form
          onSubmit={_onChangePassword}
          reducer={props.authReducer}
          validate={(values) => {
            const errors = {}

            if (!values.newPassword) {
              errors.newPassword = t('')
            } else {
              const passwordCheck = zxcvbn(values.newPassword)
              const re = /((?=.*\d)(?=.*[A-Z])(?=.*\w)).{8,}/
              const regexMatch = re.test(values.newPassword)
              if (passwordCheck.score < 2 || !regexMatch) {
                errors.newPassword = t('common.validation.passwordNotValid')
              }
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = t('common.validation.required')
            } else if (values.confirmPassword !== values.newPassword) {
              errors.confirmPassword = t('common.validation.passwordNotMatch')
            }

            return errors
          }}
          render={(props) => (
            <FormControl className={classes.formReset}>
              <form onSubmit={props.handleSubmit}>
                <img alt="SAFE" src={logo} className={classes.logo} />
                <Typography variant="h4">Wachtwoord veranderen</Typography>
                <div className={classes.paragraph}>
                  <Typography variant="body1">
                    Stel hieronder een nieuw wachtwoord in. Het nieuwe wachtwoord moet aan de
                    volgende eisen voldoen:
                  </Typography>
                  <ul>
                    <li>Minstens 8 karakters bevatten</li>
                    <li>Minstens 1 cijfer bevatten</li>
                    <li>Minstens 1 hoofdletter bevatten</li>
                    <li>
                      Niet makkelijk te raden zijn. Je ziet een groen vinkje als het wachtwoord
                      sterk genoeg is
                    </li>
                  </ul>
                </div>

                <Field
                  name="newPassword"
                  id="newPassword"
                  label="Wachtwoord"
                  component={TextFieldAdapter}
                  className={classes.inputField}
                  InputProps={{style: {backgroundColor: 'white'}}}
                  variant="outlined"
                  type="password"
                  style={{marginBottom: 12}}
                />

                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Bevestig Wachtwoord"
                  className={classes.inputField}
                  component={TextFieldAdapter}
                  type="password"
                  InputProps={{style: {backgroundColor: 'white'}}}
                  variant="outlined"
                />

                <Button
                  disabled={props.submitting}
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  type="submit"
                  fullWidth>
                  Reset Wachtwoord
                </Button>
              </form>
            </FormControl>
          )}
        />
      )}
      <Dialog
        open={props.authReducer.dialog.isShown}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">SAFE</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.authReducer.dialog.msg ?? (
              <>
                <Typography variant="h5">Bekijk je E-mail</Typography>
                <Typography variant="body1">
                  Als {props.authReducer.dialog.email} overeenkomt met het e-mailadres van je
                  account, dan sturen we instructies naar dat e-mailadres om het wachtwoord opnieuw
                  in te stellen.
                </Typography>
                <Typography variant="h5">E-mail niet ontvangen?</Typography>
                <Typography variant="body1">
                  Heb je het juiste e-mailadres opgegeven? Kijk anders ook in je spam voor een
                  e-mail van noreply@safe.rithm.nl`
                </Typography>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.dispatch(actions.clearDialog())
              history.push('/')
            }}
            color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingIndicator open={props.authReducer.isLoading} />
    </div>
  )
}

/*Het wachtwoord is succesvol veranderd. Open de SAFE app om in te loggen met dit nieuwe wachtwoord.*/

const mapStateToProps = (state) => {
  const {authReducer} = state
  return {authReducer}
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => dispatch(action)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen)
